<template>
    <div class="pa-4 mb-4" style="border: 1px solid black;">
        <formfield v-if="location" :field="location" @change="updateItem" />
        <div v-for="(section, index) in sections" :key="index">
            <h3 class="mb-8" style="opacity: 0.6;" v-if="index != 0">Nytt stycke</h3>
            <formfield v-for="(field, index) in section" :key="index" :field="field" :fields="section" @change="updateItem" />
            <v-btn class="mb-8" v-if="sections.length > 1" color="error" text @click="removeSection(index)">Ta bort stycke</v-btn>
        </div>
        <v-btn class="mr-4" color="primary" @click="addSection">+ Lägg till nytt stycke</v-btn>
        <v-btn v-if="!isNew" color="primary" :disabled="!valid" @click="save" :loading="loading.save">Spara</v-btn>
        <v-btn color="error" text @click="remove">Ta bort hälsning</v-btn>
    </div>
</template>

<script>
export default {
    name: 'packagegreetingeditcard',
    props: ['item'],
    data: () => ({
        loading: { save: false },
        location: null,
        sections: [],
    }),
    computed: {
        valid() {
            if (!this.location || !this.location.value) return false;
            for (let fields of this.sections) {
                if (_.find(fields, f => f.required && !f.value)) return false;
            }
            return true;
        },
        isNew() {
            return this.$route.params.id == 'new';
        }
    },
    mounted() {
        this.location = this.getLocationField();
        this.buildSections();
    },
    methods: {
        getLocationField() {
            return {
                id: 'page',
                label: 'Visa hälsningen på',
                type: 'select',
                value: this.item.location || null,
                required: true,
                options: [
                    { id: 'welcome', name: 'Välkomstsidan' },
                    this.item.live ?
                        { id: 'installation', name: 'Aktuell plan-sidan' } :
                        { id: 'offer', name: 'Erbjudandesidan' },
                    { id: 'gettingstarted', name: 'Kom igång-sidan' }
                ]
            };
        },
        otherVisible(fields) {
            let from = _.find(fields, { id: 'from' });
            var selected = from.options[from.value];
            return selected && selected.id == 'other';
        },
        getSectionFields() {
            return [
                { id: 'active', label: 'Aktiv', type: 'checkbox', value: true },
                { id: 'title', label: 'Rubrik', type: 'text', required: true, value: 'Hej! Jag heter {firstname} och jag är er personliga kontakt på Zoezi' },
                { id: 'from', label: 'Från', type: 'radio', value: 0, options: [
                    { id: 'customeradvisor', name: 'Kundrådgivare' },
                    { id: 'other', name: 'Annan' }
                ] },
                { id: 'otherImg', label: 'Ladda upp bild (Valfritt)', type: 'image', visible: this.otherVisible },
                { id: 'text', label: 'Text', type: 'textarea' },
            ];
        },
        buildSections() {
            if (this.item.location) {
                this.location.value = this.item.location;
            }

            this.sections = [];
            if (!this.item.sections || !this.item.sections.length) {
                this.addSection();
                return;
            }
            this.item.sections.forEach(this.addSection);
        },
        updateItem(val) {
            let sections = [];
            this.sections.forEach(fields => {
                let s = {};
                fields.forEach(field => {
                    if (!field.visible || field.visible(fields)) {
                        if (field.options) {
                            s[field.id] = field.options[field.value] && field.options[field.value].id;
                        } else {
                            s[field.id] = field.value;
                        }
                    }
                    if (!Object.keys(s).length) { return; }
                });
                sections.push(s);

            });
            this.item.sections = sections;
            this.item.location = this.location.value;

            this.$emit('update', this.item);
        },
        addSection(section) {
            let fields = this.getSectionFields();
            if (section) {
                Object.keys(section).forEach(key => {
                    let value = section[key];
                    let field = _.find(fields, { id: key });
                    if (!field) { return; }
                    if (field.type == 'select') {
                        field.value = _.find(field.options, { id: value });
                    } else if (field.type == 'radio') {
                        field.value = _.findIndex(field.options, { id: value });
                    } else {
                        field.value = value;
                    }
                });
            }
            this.sections.push(fields);
            this.updateItem();
        },
        removeSection(index) {
            this.sections.splice(index, 1);
            this.updateItem();
        },
        save() {
            this.loading.save = true;
            this.updateItem();
            fetch('/api/package/greeting/save', { method: 'POST', body: JSON.stringify(this.item) }).then(r => r.json()).then(data => {
                this.$emit('update', data);
                this.loading.save = false;
            });
        },
        remove() {
            this.$emit('remove', this.item.id);
        }
    }
};
</script>

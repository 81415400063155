<template>
  <div><h1>Försäljning</h1>
      <v-row>
        <v-col cols=4>
          <v-autocomplete
                  :items="addons_for_supplier"
                  label="Välj tillval"
                  v-model="selectedAddons"
                  item-text="name"
                  multiple
                  return-object
                  @change="loadStats"
                  :search-input.sync="tmp.addons"
          >

          <template v-slot:selection="data">
            <div v-if="selectedAddons.length > 5">{{data.index == 0 ? selectedAddons.length + ' tillval valda' : ''}}</div>
            <div v-if="selectedAddons.length <= 5">{{data.item.name}}&nbsp;</div>
          </template>

          <v-list-tile
            slot="prepend-item"
            ripple
            class="ml-1"
            @click="toggleSelectAll"
          >
            <v-list-tile-action>
              <v-icon color="primary">{{ selectedAddons.length == addons_for_supplier.length ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Alla tillval</v-list-tile-title>
          </v-list-tile>
          <v-divider
            slot="prepend-item"
            class="mt-2"
          />

          </v-autocomplete>
        </v-col>

        <v-col cols=2>
          <v-select
                  :items="costTypes"
                  label="Välj intäkt"
                  v-model="selectedCostTypes"
                  item-text="name"
                  multiple
                  return-object
                  @change="loadStats"
          >
            <template v-slot:selection="{ item, index }">
            <div v-if="index === 0">
              <span v-if="selectedCostTypes.length > 1">{{selectedCostTypes.length}} valda</span>
              <span v-if="selectedCostTypes.length == 1">{{item.name}}</span>
            </div>
          </template>

          </v-select>
        </v-col>

        <v-col cols=2>
          <v-select
                  :items="aggregationTypes"
                  label="Välj tid"
                  v-model="selectedAggregation"
                  item-text="name"
                  item-value="id"
                  @change="loadStats"
          >
          </v-select>
        </v-col>

        <v-col cols=2>
          <v-menu
            v-model="showDateMenu.fromDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fromDate"
                label="Visa försäljning från datum"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" @input="showDateMenu.fromDate = false" @change="loadStats" first-day-of-week="1"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols=2>
          <v-menu
            v-model="showDateMenu.toDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="toDate"
                label="Visa försäljning till datum"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="toDate" @input="showDateMenu.toDate = false" @change="loadStats" first-day-of-week="1"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

    <v-row v-if="selectedAddons.length">
      <v-chart :options="eusage" style="width: 100%" />
    </v-row></div>
</template>

<script>
import {getAddons} from '@/utils.js';

export default {
  name: 'salesComponent',

  data: () => ({
    addons: [],
    selectedAddons: [],
    costTypes: [{id: 'start_fee', name: 'Startkostnad'}, {id: 'usage', name: 'Transaktion'}, {id: 'month', name: 'Månadskostnad'}],
    selectedCostTypes: [],
    aggregationTypes: [{id: 'day', name: 'Dagar'}, {id: 'month', name: 'Månader'}],
    selectedAggregation: 'month',
    fromDate: new Date().addDays(-60).yyyymmdd(),
    toDate: new Date().yyyymmdd(),
    showDateMenu: {fromDate: false, toDate: false},
    usage: [],
    eusage: {
      xAxis: {
          type: 'category',
          data: []
      },
      yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} kr'
          },
      },
      series: [{
          data: [],
          type: 'line',
          itemStyle: {
              color: '#349df1'
          },
          areaStyle: {
              color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                      offset: 0, color: '#349df1' // color at 0% position
                  }, {
                      offset: 1, color: 'white' // color at 100% position
                  }],
                  global: false // false by default
              }
          },
          smooth: true
      }],
      tooltip: {
        formatter: function(params) {
          return params.name + ': ' + params.value + ' kr';
        }
      },
      dataZoom: [{
        type: 'inside'
      },{
        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '80%',
        handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
        }
      }]
    },
    tmp: {
      addons: ''
    }
  }),
  methods: {
    loadStats() {
      this.tmp.addons = '';
      if (this.selectedAddons.length == 0 || this.selectedCostTypes.length == 0) {
        this.usage = [];
        return;
      }
      let data = {fromDate: this.fromDate, toDate: this.toDate, addons: this.selectedAddons.map(a => a.id), cost_types: this.selectedCostTypes.map(ct => ct.id), aggregation: this.selectedAggregation};

      fetch('/api/addon/supplier/stats/sales/get', {method: 'POST', body: JSON.stringify(data)}).then(response => {
        response.json().then(data => {
          this.usage = data.map(v => v[1])

          this.eusage.xAxis.data = data.map(v => v[0]);
          this.eusage.series[0].data = data.map(v => v[1]);
        })
      });
    },
    toggleSelectAll() {
      if (this.selectedAddons.length == this.addons_for_supplier.length) {
        this.selectedAddons = [];
      } else {
        this.selectedAddons = this.addons_for_supplier.slice();
      }
    }
  },
  computed: {
    addons_for_supplier() {
      return this.addons.filter(x => x.supplier_id == this.$root.supplier.id).sort( (a,b) => a.name.localeCompare(b.name));
    },
    icon () {
      return this.selectedAddons.length == 0 ? 'mdi-checkbox-marked ' : 'mdi-minus-box';
    }
  },
  mounted() {
    getAddons().then(data => {
      this.addons = [];
      data.forEach(x => {
        let hasLive = false;
        x.versions.forEach(v => {
          if (v.state == 'live') {
            hasLive = true;
          }
        });

        if (hasLive) {
          this.addons.push(x);
        }
      });

      this.selectedAddons = this.addons_for_supplier.slice();
      this.selectedCostTypes = this.costTypes.slice();
      this.loadStats();
    });
  }
}
</script>

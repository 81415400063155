import Vue from 'vue'
import VueRouter from 'vue-router'
import listAddonsComponent from '@/views/listAddonsComponent.vue'
import registerComponent from '@/views/registerComponent.vue'
import setPasswordComponent from '@/views/setPasswordComponent.vue'
import settingsComponent from '@/views/settingsComponent.vue'
import reviewAddonsComponent from '@/views/reviewAddonsComponent.vue'
import statsComponent from '@/views/statsComponent.vue'
import salesComponent from '@/views/salesComponent.vue'
import showAddonComponent from '@/views/showAddonComponent.vue'
import packageList from '@/views/packageList.vue'
import pack from '@/views/package.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'list',
    component: listAddonsComponent
  },
  {
    path: '/register',
    name: 'register',
    component: registerComponent
  },
  {
    path: '/setpassword/:hash',
    name: 'setpassword',
    component: setPasswordComponent
  },
  {
    path: '/settings',
    name: 'settings',
    component: settingsComponent
  },
  {
    path: '/review',
    name: 'review',
    component: reviewAddonsComponent
  },
  {
    path: '/stats',
    name: 'stats',
    component: statsComponent
  },
  {
    path: '/sales',
    name: 'sales',
    component: salesComponent
  },
  {
    path: '/show/:addon_id',
    name: 'show',
    component: showAddonComponent
  },
  {
    path: '/branchpaket',
    name: 'packagelist',
    component: packageList
  },
  {
    path: '/branch/:id',
    name: 'package',
    component: pack
  },
  { path: '*', redirect: '/' }
];

export default new VueRouter({routes});

<template>
  <div>

    <v-tabs v-model="tab" show-arrows>
      <v-tab>Sammanfattning</v-tab>
      <v-tab>Aktiverat &amp; rekommenderat</v-tab>
      <v-tab>Hälsningar</v-tab>
      <v-tab>Välkomstsida</v-tab>
      <v-tab>Kom igång</v-tab>
      <v-tab>Kategorier</v-tab>
      <v-tab>Paket &amp; priser</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item class="pa-4">
        <v-row>
          <v-col cols=12 md=6>
            <v-text-field v-model="item.name" label="Namn" :rules="nameRules" placeholder="Skriv in namnet"></v-text-field>
            <v-textarea v-model="item.description" label="Beskrivning" placeholder="Beskrivning" auto-grow></v-textarea>
            <v-switch v-model="item._active" label="Publicerad"></v-switch>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="pa-4">
        <v-row>
          <v-col cols=12 md=12>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Funktion
                    </th>
                    <th class="text-left">
                      Aktiverat från start
                    </th>
                    <th class="text-left">
                      Rekommenderas
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in addonTable"
                    :key="item.name"
                  >
                    <td>{{ item.name }}</td>
                    <td>
                      <v-checkbox v-model="item.started" @change="onChange"></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox v-model="item.recommended" @change="onChange"></v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="pa-4">
        <v-row>
          <v-col cols=12 md=12>
            <h2>Hälsningar från kundrådgivare eller annan person</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" md="8">
            Innehållet som läggs in här visas i alla kundsystem som tillhör detta branschpaket. Väljs “Kundrådgivare” kommer bild och information automatiskt hämtas från den som är ansvarig för systemet i Runningsystems (“Ansvarig” på kundkortet).
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12 md=12>
            <packagegreetinglist :package="item" @update="updateGreetings" />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="pa-4">
        <v-row>
          <v-col cols=12 md=12>
            <h3>Välkomstsida</h3>
            <codemirror
              style="width: 100%"
              v-model="item.welcomepage"
              :options="{...cmOptionHtml, readOnly: false}"
              >
            </codemirror>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="pt-4 px-4">
        <v-row>
          <v-col cols=8 md=8>
            Kom igång-sidan är till för att hjälpa nya kunder sätta upp systemet på ett smidigt sätt. På denna sida kan du anpassa vilka steg som ska visas i systemen som tillhör detta branschpaket. Kom igång-steg som endast beror på aktiverade funktioner kommer dock alltid visas om det är aktuellt.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <package-getstarted-tasks :package="item" @update="updateTasks" />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="pa-4">
        <v-row>
          <v-col cols=12 md=12>

            <input ref="uploader" class="d-none" type="file" accept="image/*" @change="uploadImage($event.target.files[0])" />

            <v-tabs vertical>
              <v-tab v-for="item in categories" :key="item.id">
                {{item.name}}
              </v-tab>

              <v-tab-item v-for="item in categories" :key="item.id">
                <v-card flat>
                  <v-card-text>
                    <h3>{{item.name}}</h3>
                    <v-row>
                      <v-col cols=12 md=8>
                        <v-img :src="item.image || '/assets/img/noimage.png'" width="200px" height="200px"></v-img>
                      </v-col>
                      <v-col cols=12 md=4>
                        <v-btn depressed color="primary" @click="changeImage(item)">Ändra</v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols=12 md=12>
                        <codemirror
                          style="width: 100%"
                          v-model="item.html"
                          :options="{...cmOptionHtml, readOnly: false}"
                          @changes="onChange"
                          >
                        </codemirror>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>            

          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="pa-4">
        <v-row>
          <v-col cols=12 md=6>
            Paket &amp; priser
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-footer fixed>
      <v-spacer></v-spacer>
        <v-btn class="ml-2" color="primary" :disabled="!changed" @click="save" :loading="loading.save">
          Spara
        </v-btn>
    </v-footer>

  </div>
</template>

<script>
import {categories, uploadImage} from '@/utils.js';
import packageGetStartedTasks from '@/components/packageGetStartedTasks';

function send(path, data) {
  let p;
  if(arguments.length == 1) p = fetch(path);
  else p = fetch(path, {method: 'POST', body: JSON.stringify(data)});
  return p.then(r => {
    if(r.ok) return r.json();
    else return Promise.reject(r.statusText);
  });
}

export default {
  name: 'package',
  components: {
    'package-getstarted-tasks': packageGetStartedTasks
  },

  data: () => {
    return {
      isNew: false,
      tab: null,
      greetings_tab: null,
      nameRules: [
        v => v.length ? true : 'Tillvalet måste ges ett namn'
      ],
      loading: {save: false},
      orig: null,
      item: {
        name: '',
        description: '',
        welcomepage: '',
        addons: [],
        recommended: [],
        state: null,
        _live: false,
        greetings: [],
        categories: [],
        tasks: []
      },
      addons: [],
      addonTable: [],

      cmOptionHtml: {
        tabSize: 4,
        indentUnit: 4,
        lineNumbers: true,
        matchBrackets: true,
        mode: 'text/html',
        readOnly: true
      },

      categories: []
    };
  },

  methods: {
    save() {
      this.item.state = this.item._active ? 'live' : 'test';
      this.loading.save = true;
      send(this.isNew ? '/api/package/add' : '/api/package/change', this.item).then((data) => {
        if(this.isNew) this.item.id = data.id;
        this.isNew = false;
        this.loading.save = false;
        this.orig = JSON.parse(JSON.stringify(this.item));
      }).catch(() => {
        this.loading.save = false;
      });
    },
    updateGreetings(greetings) {
      this.item.greetings = _.uniqBy(greetings, g => g.id);
    },
    updateTasks(tasks) {
      this.item.tasks = _.uniqBy(tasks, g => g.id);
    },
    onChange() {
      this.item.addons = [];
      this.item.recommended = [];
      this.addonTable.forEach(r => {
        if(r.started) this.item.addons.push(r.id);
        if(r.recommended) this.item.recommended.push(r.id);
      })
      this.item.categories = this.categories.map(c => {
        return {
          id: c.id,
          image: c.image,
          html: c.html
        };
      });
    },
    changeImage(category) {
      this.$refs.uploader.click();

      this._uploadImage = (file) => {
        uploadImage(file).then((imageLink) => {
          console.log(imageLink);
          category.image = imageLink;
          this.onChange();
        })
      }
    },
    uploadImage(file) {
      this._uploadImage(file);
    }
  },

  computed: {
    changed() {
      if(!this.orig) return true;
      for(let k of ['name', 'description', '_active', 'addons', 'recommended', 'welcomepage', 'categories']) {
        if(JSON.stringify(this.orig[k]) != JSON.stringify(this.item[k])) return true;
      }
      if (this.orig.greetings.length != this.item.greetings.length) return true;
      for (let id of _.map(this.orig.greetings, 'id')) {
        let other = _.find(this.item.greetings, { id });
        if (!other) return true;
        let g = _.find(this.orig.greetings, { id });
        if (!_.isEqual(g, other)) return true;
      }
      if (this.orig.tasks.length != this.item.tasks.length) return true;
      for (let id of _.map(this.orig.tasks, 'id')) {
        let other = _.find(this.item.tasks, { id });
        if (!other) return true;
      }
      return false;
    }
  },

  mounted() {
    let loaded;
    if(this.$route.params.id == 'new') this.isNew = true;
    else {
      loaded = send('/api/package/get?id=' + this.$route.params.id).then(data => {
        data._active = data.state == 'live';

        const dataCategories = data.categories || [];
        this.categories = categories.map(c => {
          const d = dataCategories.find(d => d.id == c.id);
          return {
            id: c.id,
            name: c.name,
            image: d && d.image || null,
            html: d && d.html || ''
          };
        });

        this.item = data;
        this.orig = JSON.parse(JSON.stringify(this.item));

      })
    }

    const updateAddonTable = () => {
      this.addonTable = this.addons.map(a => {
        return {
          id: a.id,
          name: a.name,
          started: this.orig.addons && this.orig.addons.includes(a.id),
          recommended: this.orig.recommended && this.orig.recommended.includes(a.id)
        };
      })
    }

    send('/api/addon/review/get').then(addons => {
      addons.forEach(a => {
        if(a.versions.some(v => v.id == a.version_id && v.state == 'live')) {
          this.addons.push({id: a.id, name: a.name});
        }
      });

      if(loaded) loaded.then(updateAddonTable);
      else updateAddonTable();
    });
  }
}
</script>

<template>
  <div>
    <v-text-field v-if="field.type == 'text'" :label="field.label" :rules="rules" v-model="field.value" :readonly="readonly"></v-text-field>
    <v-text-field v-if="field.type == 'url' && visible(field)" :label="field.label" :rules="rules" type="url" v-model="field.value" :readonly="readonly"></v-text-field>
    <v-text-field v-if="field.type == 'number'" :label="field.label" type="number" :rules="rules" v-model="field.value" :readonly="readonly"></v-text-field>
    <v-text-field v-if="field.type == 'price'" :label="field.label" type="text" :rules="rules" v-model="field.value" :readonly="readonly" suffix="kr" min=0></v-text-field>
    <v-text-field v-if="field.type == 'decimal'" :label="field.label" type="text" :rules="rules" v-model="field.value" :readonly="readonly" min=0></v-text-field>

    <div v-if="field.type == 'list'">
      <v-btn v-if="!readonly" color="primary" dark class="mb-2" @click="addListItem()">{{field.newtitle}}</v-btn>
      <data-dialog :ref="field.ref_dialog + '_edit'" :fields="field.fields" :title="'Redigera ' + field.label" :readonly="readonly" />
      <data-dialog v-if="!readonly" :ref="field.ref_dialog + '_add'" :fields="field.fields" :title="field.newtitle" />

      <v-data-table
          :headers="field.headers"
          :items="field.value"
          :hide-default-header="false"
          :hide-default-footer="true"
          class="elevation-1"
          @click:row="editItem"
          :items-per-page="1000"
        >
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="!readonly"
            small
            @click="removeListItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-autocomplete v-if="field.type == 'select' && visible(field)"
      :items="typeof field.options === 'function' ? field.options() : field.options"
      :label="field.label"
      v-model="field.value"
      :item-text="field.getName ? field.getName : 'name'"
      item-value="id"
      :rules="rules"
      :clearable="field.clearable"
      :readonly="readonly"
    ></v-autocomplete>

    <v-radio-group v-if="field.type == 'radio'" v-model="field.value" :label="field.label">
      <v-radio v-for="option in (typeof field.options === 'function' ? field.options() : field.options)" :key="option.id" :label="option.name" />
    </v-radio-group>

    <v-card v-if="field.type == 'image' && visible(field)" v-model="field.value">
      <v-card-title v-if="field.label">{{field.label}}</v-card-title>
      <v-img v-if="field.value"
        :src="field.value"
        height="200px"
        contain
      />
      <div v-if="!field.value" style="height: 200px"></div>

      <v-card-actions>
        <div class="ma-4" style="width: 100%" v-if="loading.image">
          <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
          ></v-progress-linear>
        </div>

        <v-spacer></v-spacer>

        <v-btn icon @click="$refs.iuploader.click()" v-if="!readonly">
          <input ref="iuploader" class="d-none" type="file" accept="image/*" @change="onImageChanged($event.target.files[0])">
          <v-icon v-if="field.value">mdi-pencil</v-icon>
          <v-icon v-if="!field.value">mdi-plus</v-icon>
        </v-btn>

        <v-btn v-if="field.value && !readonly" icon @click="field.value = null">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-switch v-if="field.type == 'checkbox' && visible(field)" v-model="field.value" :readonly="readonly" :label="field.label" />

    <v-textarea v-if="field.type == 'textarea'" :label="field.label" :rules="rules" v-model="field.value" :readonly="readonly"></v-textarea>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import {decimalR} from '@/utils.js';

export default {
  name: 'formfield',
  props: ['field', 'readonly', 'fields'],
  data: function () {
    return {
      rules: [
        v => (!this.field.required || v) ? true : 'Värdet måste fyllas i',
        v => this.field.type != 'url' || (!v || v.startsWith('https://')) ? true : 'Ingen giltig webbadress',
        v => (['price', 'decimal'].indexOf(this.field.type) == -1 || !v || decimalR.test(v)) ? true : 'Inget giltigt decimaltal',
        v => this.field.rule ? this.field.rule(v) : true
      ],
      loading: {}
    }
  },
  watch: {
    'field.value'(val) {
      this.$emit('change', val);
    }
  },
  methods: {
    addListItem() {
      let self = this;
      this.$refs[this.field.ref_dialog + '_add'].show().then(function(value) {
        self.field.value.push(value);
      });
    },
    removeListItem(item) {
      let i = this.field.value.indexOf(item);
      this.field.value.splice(i, 1);
    },
    editItem(item) {
      let self = this;
      let i = this.field.value.indexOf(item);
      this.$refs[this.field.ref_dialog + '_edit'].show(item).then(function(value) {
        Vue.set(self.field.value, i, Object.assign({}, self.field.value[i], value));
      })
    },
    visible(field) {
      if (field.visible === undefined) {
        return true;
      }
      if (typeof field.visible == 'string') {
        if (field.visible.length) {
          let name = field.visible;
          let negate = field.visible[0] == '!';
          if (negate) {
            name = field.visible.substring(1);
          }
          let f = _.find(this.fields, {id: name});
          if (f) {
            return negate ? !f.value : !!f.value;
          }
        }
      }
      else if (typeof field.visible == 'function') {
        return field.visible(this.fields);
      }
    },
    onImageChanged(file) {
      this.loading.image = true;
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        let data = {image: fr.result};
        fetch('/api/addon/supplier/image/upload', {method: 'POST', body: JSON.stringify(data) }).then(response => {
          response.json().then(image => {
            this.field.value = image.url;
            this.$refs.iuploader.value = null;
            this.loading.image = false;
          })
        });
      })
    }
  }
}
</script>

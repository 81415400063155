<style>
    #packagesgetstartedtasks-copy-field > * {
        display: inline-block;
        margin-right: 2ex;
    }
    .packagesgetstartedtasks-list-header {
        color:  rgba(0,0,0,0.6);
        font-size: small;
        font-weight: bold;
    }
</style>
<template>
    <div class="mb-10" v-model="key">
        <confirm-dialog
            ref="confirm"
            headline="Är du säker?"
            text="Alla  inställningar som gjorts på denna sida kommer skrivas över och kan inte ångras"
            button="Kopiera"
        />
        <div id="packagesgetstartedtasks-copy-field" v-if="copyField">
            <span class="text-subtitle-1">Kopiera kom igång-stegen från branschpaket</span>
            <formfield :field="copyField" />
            <v-btn color="info" @click="confirmCopy" :loading="loading.copy" :disabled="!copyField.value">Kopiera</v-btn>
        </div>

        <div v-if="items.length">
            <v-text-field
                class="col-lg-5"
                v-model="searchText"
                append-icon="mdi-magnify"
                label="Sök kort eller guide-ID"
                single-line
                hide-details
            />

            <v-list class="elevation-1">
                <v-list-item>
                    <v-row>
                        <v-col cols=1 class="packagesgetstartedtasks-list-header">
                            Ordning
                        </v-col>
                        <v-col cols=5 class="packagesgetstartedtasks-list-header">
                            Kom igång-steg
                        </v-col>
                        <v-col cols=3 class="packagesgetstartedtasks-list-header">
                            Innehåller guider
                        </v-col>
                        <v-col cols=2 class="packagesgetstartedtasks-list-header">
                            Visa i Kom igång
                        </v-col>
                    </v-row>
                </v-list-item>
                <draggable v-model="items" @start="drag=true" @end="drag=false">
                    <v-list-item v-for="item in filteredItems" :key="item.id">
                        <v-row>
                            <v-col cols=1 style="text-align: center;">
                                {{ item.order }}
                            </v-col>
                            <v-col cols=5>
                                {{ item.title }}
                            </v-col>
                            <v-col cols=3>
                                {{item.guide ? item.guide.title : ''}}
                            </v-col>
                            <v-col cols=2>
                                <v-checkbox class="mt-0 pt-0" v-model="item.show" @click.native.stop @change="update"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </draggable>
            </v-list>

        </div>
    </div>
</template>
<script>
import Draggable from 'vuedraggable';

export default {
    name: 'packageGetStartedTasks',
    props: ['package'],
    components: {
        Draggable,
    },
    data() {
        return {
            key: 0,
            loading: { copy: false },
            searchText: '',
            copyField: {
                id: 'copy',
                label: 'Välj paket',
                type: 'select',
                value: null,
                required: false,
                options: []
            },
            items: [],
            drag: false
        };
    },
    computed: {
        filteredItems() {
            if (!this.searchText) { return this.items; }
            let text = this.searchText.toLowerCase();
            return _.filter(this.items, item => (item.title.toLowerCase().includes(text) || item.guide?.title.toLowerCase().includes(text)));
        }
    },
    mounted() {
        this.loadPackages();
        this.loadItems();
    },
    watch: {
        package() {
            this.loadItems();
        },
        drag(newValue, oldValue) {
            if (newValue == false && oldValue) {
                this.update();
            }
        }
    },
    methods: {
        loadPackages() {
            fetch('/api/package/get').then(r => r.json()).then(packages => {
                this.copyField.options = packages;
            });
        },
        loadItems() {
            fetch('/api/package/getstarted/task/get').then(r => r.json()).then(tasks => {
                tasks.forEach(task => {
                    task.show = !!_.find(this.package.tasks, { id: task.id });
                    if (task.show) {
                        task.order = _.indexOf(this.package.task_order, task.id)+1;
                    }
                });
                this.items = _.sortBy(tasks, ['order']);
            });
        },
        update() {
            let order = 1;
            this.items.forEach(task => (task.order = task.show ? order++ : null));
            let tasks = _.filter(this.items, task => task.show);
            this.$emit('update', tasks);
            this.key++;
        },
        confirmCopy() {
            this.$refs.confirm.show().then(this.copy);
        },
        copy() {
            this.loading.copy = true;
            fetch(`/api/package/getstarted/task/get?package_id=${this.copyField.value}`).then(r => r.json()).then(tasks => {
                this.items.forEach(task => {
                    task.show = !!_.find(tasks, { id: task.id });
                });
                this.copyField.value = null;
                this.update();
                this.loading.copy = false;
            });
        },
        rowClick(task) {
            task.show = !task.show;
            this.update()
        }
    }
};
</script>


import Vue from 'vue'

export const decimalR = RegExp('^[0-9]+([,.][0-9]+)?$');

export function getAddons() {
  return fetch('/api/addon/supplier/get').then(response => response.json()).then(data => {
    if (!data.length) {
      return [];
    }
    data.forEach(addon => {
      addon.version = addon.versions.find(v => v.id == addon.version_id);
    })

    return data.filter(x => x.version);
  });
}

export function getRandomId() {
  return '0'.repeat(64).replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
}

export function getRandomIdShort() {
  return '0'.repeat(32).replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
}

var translations = {};
export function fetchLanguageFile(language) {
  fetch('/api/public/translation/get?language=' + language).then(response => response.json() ).then(data => {
    translations = data;
  });
}
fetchLanguageFile('sv');

export function translate(x) {
  return translations[x] || x;
}

Vue.filter('translate', function (value) {
  return translate(value);
});

Date.prototype.addDays = function(days) {
  this.setDate(this.getDate() + days);
  return this;
};

Date.prototype.yyyymmdd = function() {
  var yyyy = this.getFullYear().toString();
  var mm = (this.getMonth()+1).toString(); // getMonth() is zero-based
  var dd  = this.getDate().toString();
  return yyyy + '-' + (mm[1]?mm:'0'+mm[0]) + '-' + (dd[1]?dd:'0'+dd[0]); // padding
};


export const categories = [
  {id: 'booking', name: 'Bokning'},
  {id: 'economy', name: 'Koll på ekonomin'},
  {id: 'customers', name: 'Kundhantering'},
  {id: 'youroffer', name: 'Ert erbjudande'},
  {id: 'onsite', name: 'På plats i lokalen'},
  {id: 'charge', name: 'Ta betalt'},
  {id: 'frontend', name: 'Ansiktet utåt'},
  {id: 'staff', name: 'Vardag för personalen'}
];


export function uploadImage(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsDataURL(file);
    fr.addEventListener('load', () => {
      let data = {image: fr.result};
      fetch('/api/addon/supplier/image/upload', {method: 'POST', body: JSON.stringify(data) }).then(response => {
        response.json().then(image => {
          resolve(image.url);
        }, reject);
      }, reject);
    })
  });
}

<template>
  <div>
    <v-row>
      <v-col cols=12>
        <h3>Välkommen till Zoezis utvecklarportal för leverantörer</h3>

        <p>Här kan du som leverantör hantera tillval. Börja med att trycka på <i>Skapa nytt tillval</i> här nedan och fyll sedan i de uppgifter som behövs. Därefter skickar du in tillvalet för granskning.</p>

        <p>När tillvalet väl är granskat och klart kan du följa försäljning och användning på den här sidan.</p>

        <p>För mer information om hur man tillverkar ett tillval, <a :href="apidoclink" target="_blank">följ den här länken</a></p>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-btn color="primary" dark class="mb-2" @click="newAddon()">Skapa nytt tillval</v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="addons.length > 5"
          v-model="addon_search"
          append-icon="mdi-magnify"
          label="Sök"
          single-line
          hide-details
          @keydown="searchKeyDown"
        ></v-text-field>
      </v-card-title>
      <confirm-remove ref="confirmremove" />
      <v-data-table v-if="addons_for_supplier && addons_for_supplier.length"
        ref="addontable"
        :headers="headers"
        :items="addons_for_supplier"
        class="elevation-1"
        :hide-default-header="$root.isSmallScreen"
        :hide-default-footer="true"
        must-sort
        :items-per-page="1000"
        :search="addon_search"
        @click:row="rowClick"
      >
        <template v-slot:item.image="{ item }">
          <img :src="item.version.image" width="50" height="50" class="mt-2" />
        </template>
        <template v-slot:item.state="{ item }">
          {{getState(item.version.state)}}
        </template>
        <template v-slot:item.version="{ item }">
          {{item.version.version_number}}
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="rowClick(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="canRemove(item)"
            small
            @click="removeVersion(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {getAddons} from '@/utils.js';

export default {
  name: 'listAddonsComponent',
  data: () => ({
    addons: [],
    addon_search: ''
  }),
  computed: {
    headers() {
      const t = this.$translate;
      this.$root.language; // Reference it
      return [{ text: '', value: 'image', sortable: false},{ text: t('Name'), value: 'name'}, {text: 'ID', value: 'id'}, {text: 'Version', value: 'version'}, {text: 'Status', value: 'state'}, {text: '', value: 'action', sortable: false}];
    },
    addons_for_supplier() {
      return this.addons.filter(x => this.$root.supplier && x.supplier_id == this.$root.supplier.id).sort( (a, b) => a.name.localeCompare(b.name));
    },
    apidoclink() {
      return 'https://' + document.location.hostname + '/apidoc/index.html#addons';
    }
  },
  mounted() {
    this.updateAddons();
  },
  methods: {
    updateAddons() {
       getAddons().then(data => {
          this.addons = data;
       });
    },
    rowClick(addon) {
      if (this.$refs.confirmremove.showDialog) {
        return;
      }
      this.$router.push({name: 'show', params: {addon_id: addon.id}});
    },
    getState(state) {
      switch (state) {
        case 'test': return 'Under utveckling';
        case 'review': return 'Granskas';
        case 'live': return 'Publicerad';
      }
    },
    newAddon() {
      let data = {name: 'Nytt tillval', supplier_id: this.$root.supplier.id};
      fetch('/api/addon/supplier/add', {method: 'POST', body: JSON.stringify(data)}).then(response => {
        if (response.status == 200) {
          response.json().then(addon => this.$router.push('/show/' + addon.id))
        } else if (response.status == 401) {
          this.$root.onLoggedOut();
        } else {
          this.$root.showErrorDialog('Fel', 'Kunde inte lägga till nytt tillval');
        }
      });
    },
    searchKeyDown(e) {
      if (e.keyCode == 13 && this.$refs.addontable.selectableItems.length == 1) {
        this.rowClick(this.$refs.addontable.selectableItems[0]);
      }
    },
    canRemove(addon) {
      return addon.version.state == 'test';
    },
    removeVersion(addon) {
      this.$refs.confirmremove.show('Vill du verkligen ta bort utvecklingsversionen av tillvalet?').then(() => {
        let data = {id: addon.version.id};
        fetch('/api/addon/supplier/version/remove', {method: 'POST', body: JSON.stringify(data)}).then(() => {
          this.updateAddons();
        });
      });
    }
  }
};
</script>

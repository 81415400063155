<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn color="primary" dark class="mb-2" @click="add()">Skapa nytt branschpaket</v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          label="Sök"
          single-line
          hide-details
          @keydown="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :hide-default-header="$root.isSmallScreen"
        :hide-default-footer="true"
        must-sort
        :items-per-page="1000"
        :search="searchText"
        @click:row="rowClick"
      >
        <template v-slot:item.image="{ item }">
          <!-- 
            <img :src="item.image" width="50" height="50" class="mt-2" />
            -->
        </template>
        <template v-slot:item.state="{ item }">
          {{getState(item.state)}}
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="rowClick(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="canRemove(item)"
            small
            @click="removeItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
//import router from '@/router'
//import {getAddons} from '@/utils.js';

export default {
  name: 'packageList',
  data: () => ({
    headers: [
      {text: '', value: 'image', sortable: false},
      {text: 'Namn', value: 'name'},
      //{text: 'ID', value: 'id'},
      {text: 'Status', value: 'state'},
      {text: '', value: 'action', sortable: false}
    ],
    searchText: '',
    items: []
  }),
  computed: {
  },
  mounted() {
    fetch('/api/package/get').then(r => r.json()).then(data => {
      this.items = data;
    });
  },
  methods: {
    getState(state) {
      switch (state) {
        case 'test': return 'Under utveckling';
        case 'review': return 'Granskas';
        case 'live': return 'Publicerad';
      }
      return '';
    },
    search() {

    },
    rowClick(item) {
      this.$router.push({name: 'package', params: {id: item.id}});
    },
    add() {
      this.$router.push({name: 'package', params: {id: 'new'}});
    },
    canRemove(item) {
      return true;
    },
    removeItem(item) {

    }
  }
};
</script>

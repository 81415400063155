<style>
.v-tab {
    text-transform: none !important;
}
</style>

<template>
  <div v-if="addon">
    <confirm-remove ref="confirmremove" />
    <div class="title">{{addon.name}}{{!$root.supplier || addon.supplier_id != $root.supplier.id ? ' av ' + selectedVersion.supplier_name : ''}}</div>
      <v-form v-model="valid">
        <v-select
                :items="addon.versions"
                label="Välj version"
                solo
                v-model="selectedVersion"
                :item-text="getVersionName"
                return-object
                @change="versionSelected"
        ></v-select>
  
        <v-tabs v-model="tab" show-arrows>
          <v-tab>Butiksinformation</v-tab>
          <v-tab>Funktionsbeskrivning</v-tab>
          <v-tab>Pris</v-tab>
          <v-tab>
            <v-badge
              color="grey"
              :content="changed.settings.length"
              :value="changed.settings.length > 0"
            >
              Inställningar
            </v-badge>
            </v-tab>
          <v-tab>
            <v-badge
              color="grey"
              :content="changed.widgets.length"
              :value="changed.widgets.length > 0"
            >
              Widgets
            </v-badge>
          </v-tab>
          <v-tab>
            <v-badge
              color="grey"
              :content="changed.webhooks.length"
              :value="changed.webhooks.length > 0"
            >
              Webhooks
            </v-badge>
          </v-tab>
          <v-tab>
            <v-badge
              color="grey"
              :content="changed.mailinghandlers.length"
              :value="changed.mailinghandlers.length > 0"
            >
              Utskickshanterare
            </v-badge>
          </v-tab>
          <v-tab>
            <v-badge
              color="grey"
              :content="changed.components.length"
              :value="changed.components.length > 0"
            >
              Komponenter
            </v-badge>
          </v-tab>
          <v-tab>
            <v-badge
              color="grey"
              :content="changed.apis.length"
              :value="changed.apis.length > 0"
            >
              API:er
            </v-badge>
          </v-tab>
          <v-tab>
            <v-badge
              color="grey"
              :content="changed.functions.length"
              :value="changed.functions.length > 0"
            >
              Funktioner
            </v-badge>
          </v-tab>
          <v-tab>Granskning</v-tab>
        </v-tabs>
  
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item class="pa-4">
            <v-row>
              <v-col cols=12 md=6>
                <v-text-field v-model="changed.name" :readonly="readonly" :label="$translate('Name')" :rules="nameRules" placeholder="Skriv in namnet på tillvalet"></v-text-field>
                <v-text-field v-model="changed.supplier_mail" :readonly="readonly" :rules="emailRules" :label="$translate('E-mail to support')"></v-text-field>
                <v-text-field v-model="changed.onstart_mail" :readonly="readonly" :rules="emailRules" label="Skicka e-post hit vid start av tillval"></v-text-field>
                <v-autocomplete :search-input.sync="tmp.modules" @change="tmp.modules = ''" v-model="changed.modules" :readonly="readonly" label="Grundfunktionalitet som tillvalet kräver" :items="modules" item-text="name" item-value="id" :clearable="!readonly" multiple></v-autocomplete>
                <v-autocomplete :search-input.sync="tmp.permissions_granted" @change="tmp.permissions_granted = ''" v-model="changed.permissions_granted" :readonly="readonly" :label="$translate('Permissions the addon will be granted')" :items="permissions" item-text="name" item-value="id" :clearable="!readonly" multiple></v-autocomplete>
                <v-autocomplete v-model="changed.category" :readonly="readonly" label="Tillhör kategori" :items="categories" item-text="name" item-value="id" :clearable="!readonly"></v-autocomplete>

                <v-select
                  v-if="packages.length"
                  :items="packages"
                  label="Aktiveras från start i branschpaket"
                  item-text="name"
                  item-value="name"
                  multiple
                  :readonly="readonly"
                  v-model="defaultForPackage"
                ></v-select>

                <v-select
                  v-if="packages.length"
                  :items="packages"
                  label="Rekommenderas för branschpaket"
                  item-text="name"
                  item-value="name"
                  multiple
                  :readonly="readonly"
                  v-model="recommendedForPackage"
                ></v-select>

                <v-textarea v-model="changed.description" :readonly="readonly" label="Beskrivning" placeholder="Beskrivning av tillvalet som kunder kommer se" auto-grow></v-textarea>
                <v-textarea v-model="changed.onstart_text" :readonly="readonly" label="Uppstartsinformation" placeholder="Information som visas för kunden när tillvalet läggs till" auto-grow></v-textarea>
              </v-col>
              <v-col cols=12 md=6>
                <v-row>
                  <v-col cols=12>
                    <v-card>
                      <v-card-title>Bild</v-card-title>
                      <v-img v-if="changed.image"
                        :src="changed.image"
                        height="200px"
                        contain
                        @click="showImageDialog = true"
                      >
                      </v-img>

                      <v-dialog v-model="showImageDialog" max-width="90vw">
                        <v-card>
                          <v-img :src="changed.image" contain max-height="80vh"  />
                          <v-card-actions>
                            <v-spacer />
                            <v-btn @click="showImageDialog = false" color="secondary" text>Stäng</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <div v-if="!changed.image" style="height: 200px">
                      </div>
  
                      <v-card-actions>
                        <div class="ma-4" style="width: 100%" v-if="loading.image">
                          <v-progress-linear
                            color="primary"
                            indeterminate
                            rounded
                            height="6"
                          ></v-progress-linear>
                        </div>
  
                        <v-spacer></v-spacer>
  
                        <v-btn icon @click="$refs.iuploader.click()" v-if="!readonly">
                          <input ref="iuploader" class="d-none" type="file" accept="image/*" @change="onImageChanged($event.target.files[0])">
                          <v-icon v-if="changed.image">mdi-pencil</v-icon>
                          <v-icon v-if="!changed.image">mdi-plus</v-icon>
                        </v-btn>
  
                        <v-btn v-if="changed.image && !readonly" icon @click="removeImage()">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=12>
                    <v-card>
                      <v-card-title>Skärmbilder</v-card-title>
  
                      <v-carousel
                        height="200"
                        width="200"
                        :hide-delimiters="changed.screenshots.length == 0"
                        show-arrows-on-hover
                        v-model="selectedScreenshot">
                            <v-carousel-item                      
                              v-for="screenshot in changed.screenshots"
                              :key="screenshot"
                            >
                              <v-sheet
                                height="100%"
                                tile
                              >
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center"
                                >
                                <v-img :src="screenshot" max-height="200px" contain @click="showScreenshotDialog = true" />
                                </v-row>
                              </v-sheet>

                              <v-dialog v-model="showScreenshotDialog" max-width="90vw">
                              <v-card>
                                <v-img :src="screenshot" contain max-height="80vh"  />
                                <v-card-actions>
                                  <v-spacer />
                                  <v-btn @click="showScreenshotDialog = false" color="secondary" text>Stäng</v-btn>
                                </v-card-actions>
                              </v-card>                        
                            </v-dialog>
                            </v-carousel-item>
                            
                      </v-carousel>
                      <v-card-actions>
                        <div class="ma-4" style="width: 100%" v-if="loading.screenshot">
                          <v-progress-linear
                            color="primary"
                            indeterminate
                            rounded
                            height="6"
                          ></v-progress-linear>
                        </div>
                        <v-spacer></v-spacer>
  
                        <v-btn icon @click="$refs.uploader.click()" v-if="!readonly">
                          <input ref="uploader" class="d-none" type="file" accept="image/*" @change="onScreenshot($event.target.files[0])">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
  
                        <v-btn v-if="changed.screenshots.length && !readonly" icon @click="removeScreenshot()">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="canRemove">
                  <v-col cols=12>
                    <v-btn text @click="remove" color="error">Ta bort tillvalet</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="pa-4">
            <v-row>
              <v-col cols=12 md=12>
                <v-preview :item="changed" @update="changed.preview = $event;"></v-preview>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="pa-4">
            <v-row>
              <v-col cols=12 md=6>
                <v-text-field v-model="changed.start_fee" :readonly="readonly" type="number" suffix="kr" min=0 label="Startkostnad"></v-text-field>
                <v-text-field v-model="changed.price_per_month" :readonly="readonly" suffix="kr" type="number" min=0 label="Pris per månad"></v-text-field>
                <v-switch v-model="changed.use_transactions" :readonly="readonly" :label="'Ta betalt per ' + getUsageName(1)" @change="useTransactionsChange" />
                <v-text-field v-if="changed.use_transactions" v-model="changed.usage_name" :readonly="readonly" label="Namn på användning" type="text" :rules="requiredRules" placeholder="Exempel: faktura,fakturor"></v-text-field>
                <v-text-field v-if="changed.use_transactions" v-model="changed.free_uses_per_month" :readonly="readonly" :label="'Antal fria ' + getUsageName(2) + ' per månad'" type="number" min=0></v-text-field>
                <v-text-field v-if="changed.use_transactions" v-model="changed.price_per_usage" :readonly="readonly" :rules="decimalRule" suffix="kr" min=0 :label="'Pris per ' + getUsageName(1)"></v-text-field>
                <v-text-field v-if="changed.use_transactions" v-model="changed.price_per_usage_percent" :readonly="readonly" :rules="decimalRule" suffix="%" :label="'Procent per ' + getUsageName(1)"></v-text-field>
              </v-col>
            </v-row>
  
            <v-row v-if="$root.reviewer.list">
              <v-col cols=12>
                <v-btn v-if="!readonly" color="primary" dark class="mb-2" @click="newPriceRule()">Ny prisregel</v-btn>
                <data-dialog ref="price_dialog" :fields="changed.use_transactions ? price_fields_transaction : price_fields" title="Priser" :readonly="readonly" />
  
                <v-data-table v-if="changed.price_rules && changed.price_rules.length > 0"
                    :headers="changed.use_transactions ? priceHeadersTransaction : priceHeaders"
                    :items="changed.price_rules"
                    :hide-default-header="$root.isSmallScreen"
                    :hide-default-footer="true"
                    class="elevation-1"
                    disable-sort
                    @click:row="priceRuleClick"
                    :items-per-page="1000"
                  >
                  <template v-slot:item.domain="{ item }">
                    {{getDomainNameFromId(item.domain_id)}}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="priceRuleClick(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="!readonly"
                      small
                      @click.stop="removePriceRule(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
  
            <v-row v-if="$root.reviewer.list">
              <v-col cols=12>
                <v-switch v-model="changed.for_all" :readonly="readonly" label="Tillgängligt för samtliga Zoezi-kunder" />
  
                <div v-if="!changed.for_all">
                  <v-btn v-if="!readonly" color="primary" dark class="mb-2" @click="newAvailableRule()">Ny tillgänglighetsregel</v-btn>
                  <data-dialog ref="available_dialog" :fields="available_fields" title="Tillgänglighet" :readonly="readonly" />
  
                  <v-data-table
                      :headers="availableHeaders"
                      :items="changed.available_rules"
                      :hide-default-header="$root.isSmallScreen"
                      :hide-default-footer="true"
                      class="elevation-1"
                      disable-sort
                      @click:row="availableRuleClick"
                      :page="1000"
                    >
                    <template v-slot:item.chain="{ item }">
                      {{ item.inverted ? 'Ej ' : '' }}{{item.chain}}
                    </template>
                    <template v-slot:item.domain="{ item }">
                      {{getDomainNameFromId(item.domain_id)}}
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="availableRuleClick(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        v-if="!readonly"
                        small
                        @click.stop="removeAvailableRule(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
  
          </v-tab-item>
          <v-tab-item>
            <div class="pa-4">  
              <v-row>
                <v-col cols=12 md=4>
                  <v-input v-if="!changed.unique_apikey" label="API-nyckel" :hint="addon.apikey" persistent-hint></v-input>
                  <v-switch v-model="changed.unique_apikey" :readonly="readonly" label="Varje kund får egen API-nyckel" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=12 md=4>
                  <v-input label="Webhook-nyckel" :hint="addon.webhookkey" persistent-hint></v-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=12 md=6>
                  <v-select
                    :items="getTestSystems()"
                    label="Testsystem"
                    v-model="changed.test_hostname"
                    :readonly="readonly"
                    item-text="name"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=12 md=6>
                  I testsystemet går det att logga in och testa tillvalet. Användarnamn för inloggning: <b>{{$root.supplier.mail}}</b><br>
                  För att starta tillvalet, gå till Tillvalsbutiken.<br>
                  <br>
                  <a v-if="changed.test_hostname" :href="'https://' + changed.test_hostname + '/staff'" target="_blank">Gå till testsystemet</a>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols=12 md=6>
                <v-btn v-if="!readonly" color="primary" dark class="mb-2" @click="newSetting()">Ny inställning</v-btn>
                <data-dialog ref="settings_dialog" :fields="settings_fields" title="Inställning" :readonly="readonly" />
  
                <v-data-table v-if="changed.settings.length"
                    :headers="settingsHeaders"
                    :items="changed.settings"
                    :hide-default-header="$root.isSmallScreen"
                    :hide-default-footer="true"
                    sort-by="name"
                    must-sort
                    class="elevation-1"
                    @click:row="settingsClick"
                    :items-per-page="1000"
                  >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="settingsClick(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="!readonly"
                      small
                      @click.stop="removeSetting(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row v-if="$root.reviewer.list">
              <v-col cols=12 md=6>
                <v-btn v-if="!readonly" color="primary" dark class="mb-2" @click="newPermission()">Ny behörighet</v-btn>
                <data-dialog ref="permissions_dialog" :fields="permissions_fields" title="Behörighet" :readonly="readonly" />
  
                <v-data-table v-if="changed.permissions_provided && changed.permissions_provided.length"
                    :headers="permissionsHeaders"
                    :items="changed.permissions_provided"
                    :hide-default-header="$root.isSmallScreen"
                    :hide-default-footer="true"
                    sort-by="id"
                    must-sort
                    class="elevation-1"
                    @click:row="permissionsClick"
                    :items-per-page="1000"
                  >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="permissionsClick(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="!readonly"
                      small
                      @click.stop="removePermission(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

              <v-row v-if="git">
                <v-col cols=12>
                  <h2 class="mt-2">Git</h2>
                  <div v-if="gitRepoStatus && gitRepoStatus.has_repo">
                    Git är uppsatt för detta tillval.<br>
                    För att checka ut:<br>
                    <code>{{gitRepoStatus.clone}}</code><br>
                  </div>
                  <div v-else>
                    <v-btn color="primary" @click="gitInit" :loading="loading.gitInit">Initiera git för detta tillval</v-btn>
                  </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols=12 md=6>
                <v-btn color="primary" dark class="mb-2" @click="newWidget()" v-if="!readonly">Ny widget</v-btn>
                <data-dialog ref="widget_dialog" :fields="widget_fields" title="Widget" :readonly="readonly" />
  
                <v-data-table v-if="changed.widgets.length"
                    :headers="widgetHeaders"
                    :items="changed.widgets"
                    :hide-default-header="$root.isSmallScreen"
                    :hide-default-footer="true"
                    sort-by="name"
                    item-value="id"
                    must-sort
                    class="elevation-1"
                    @click:row="widgetClick"
                    :items-per-page="1000"
                  >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="widgetClick(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="!readonly"
                      small
                      @click.stop="removeWidget(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:item.type="{ item }">
                    {{widgetType(item.type)}}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols=12 md=6>
                <v-dialog v-model="showWebhookDialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on" :disabled="readonly">Ny webhook</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Webhook</span>
                    </v-card-title>
  
                    <v-card-text>
                      <v-text-field v-model="editingWebhookItem.name" label="Namn" :readonly="readonly"></v-text-field>
                      <v-switch v-model="editingWebhookItem.useFunction" label="Använd inbyggd funktion" :readonly="readonly" />
                      <v-select v-if="editingWebhookItem.useFunction"
                        v-model="editingWebhookItem.functionName"
                        label="Funktion"
                        :items="changed.functions"
                        item-value="id"
                        item-text="name"
                        :readonly="readonly"
                      ></v-select>
                      <v-text-field v-if="!editingWebhookItem.useFunction" v-model="editingWebhookItem.url" label="URL"></v-text-field>
  
                      <v-autocomplete
                        :items="events"
                        label="Händelser"
                        :item-text="getEventName"
                        item-value="id"
                        multiple
                        :readonly="readonly"
                        v-model="editingWebhookItem.events"
                        :search-input.sync="tmp.webhook" @change="tmp.webhook = ''"
                      ></v-autocomplete>
  
                      <v-select
                        :items="webhook_actions"
                        label="Händelsefilter"
                        item-text="name"
                        item-value="id"
                        multiple
                        :readonly="readonly"
                        v-model="editingWebhookItem.actions"
                      ></v-select>
  
                    </v-card-text>
  
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" text @click="closeWebhook">Avbryt</v-btn>
                      <v-btn color="primary" text @click="saveWebhook" :disabled="readonly || !editingWebhookItem.name">Spara</v-btn>
                    </v-card-actions>
                  </v-card>
  
                </v-dialog>
  
                <v-data-table v-if="changed.webhooks.length"
                    :headers="webhookHeaders"
                    :items="changed.webhooks"
                    :hide-default-header="$root.isSmallScreen"
                    :hide-default-footer="true"
                    sort-by="name"
                    must-sort
                    class="elevation-1"
                    @click:row="webhookClick"
                    :items-per-page="1000"
                  >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="webhookClick(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon v-if="!readonly"
                      small
                      @click.stop="removeWebhook(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <!-- Utskickshanterare -->
            <v-row>
              <v-col cols=12 md=6>
                <v-btn color="primary" dark class="mb-2" @click="newMailingHandler()" v-if="!readonly">Ny utskickshanterare</v-btn>
                <data-dialog ref="mailinghandler_dialog" :fields="mailinghandler_fields" title="Utskickshanterare" :readonly="readonly" />

                <v-data-table v-if="changed.mailinghandlers.length"
                    :headers="mailingHandlerHeaders"
                    :items="changed.mailinghandlers"
                    :hide-default-header="$root.isSmallScreen"
                    :hide-default-footer="true"
                    sort-by="name"
                    item-value="id"
                    must-sort
                    class="elevation-1"
                    @click:row="mailingHandlerClick"
                    :items-per-page="1000"
                  >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="mailingHandlerClick(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="!readonly"
                      small
                      @click.stop="removeMailingHandler(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <!-- Komponenter -->
            <v-row>
              <v-col cols=12> 
                <v-btn v-if="!readonly" color="primary" @click="newComponent">Ny komponent</v-btn>
              </v-col>
            </v-row>
  
            <v-row v-if="changed.components && changed.components.length">
              <v-col cols=12 md=6>
                <v-select
                  :items="sortedComponents"
                  label="Välj komponent"
                  solo
                  v-model="selectedComponent"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols=12 md=6 v-if="selectedComponent && selectedComponent.type != 'vue3'">
                Använd i andra komponenter som: <code>&lt;zoezi-addon-{{selectedComponent.id}} /&gt;</code>
              </v-col>
            </v-row>
            <v-row v-if="selectedComponent && !readonly">
              <v-col cols=12 md=6>
                <v-text-field label="Namn" v-model="selectedComponent.name"></v-text-field>
              </v-col>
              <v-col cols=12 md=4>
                <v-select
                  :items="component_types"
                  label="Välj komponenttyp"
                  solo
                  v-model="selectedComponent.type"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  :hint="component_types.find(x => x.id == selectedComponent.type) && component_types.find(x => x.id == selectedComponent.type).hint"
                />
              </v-col>
              <v-col cols=12 md=2>
                <v-btn color="secondary" class="ml-4" @click="removeComponent">Ta bort komponenten</v-btn>
              </v-col>
            </v-row>
  
            <div v-if="selectedComponent">
              <v-tabs>
                <v-tab @change="refreshEditor('html')">HTML</v-tab>
                <v-tab @change="refreshEditor('js')">Javascript</v-tab>
                <v-tab @change="refreshEditor('css')">CSS</v-tab>
                <v-tab v-if="selectedComponent.type == 'vue3'" @change="refreshEditor('dependencies')">Dependencies</v-tab>
                <v-tab v-if="selectedComponent.type == 'vue3'">Kompilering</v-tab>

                <v-tab-item>
                  <CodeEditor ref="html" v-model="selectedComponent.template" type="html" />
                </v-tab-item>

                <v-tab-item>
                  <CodeEditor ref="js" v-model="selectedComponent.code" type="js"/>
                </v-tab-item>

                <v-tab-item>
                  <CodeEditor ref="css" v-model="selectedComponent.css" type="css" />
                </v-tab-item>

                <v-tab-item v-if="selectedComponent.type == 'vue3'">
                  <CodeEditor ref="dependencies" v-model="selectedComponent.dependencies" type="json"/>
                </v-tab-item>

                <v-tab-item v-if="selectedComponent.type == 'vue3'">
                  <v-btn @click="compile(selectedComponent)" :loading="compileResult.compiling" color="primary" :disabled="addonChanged">Kompilera</v-btn>

                  <div v-if="compileResult.component_id == selectedComponent.id" class="mt-2">
                    <div v-if="compileResult.result.result">
                        <div><v-icon color="success" class="mr-2">mdi-check</v-icon>Kompilering klar</div>
                        <div>Filstorlek: {{compileResult.result.size}} bytes</div>
                    </div>
                    <div v-else>
                      <div><v-icon color="error" class="mr-2">mdi-alert</v-icon>Kompilering misslyckades</div>
                      <div style="white-space: pre-line" v-text="compileResult.result.output" class="mt-2"></div>
                    </div>
                  </div>
                </v-tab-item>

              </v-tabs>
            </div>
          </v-tab-item>
  
          <v-tab-item>
            <h1>API:er</h1>
  
            <v-row>
              <v-col cols=12 md=6>
                <v-btn color="primary" dark class="mb-2" @click="newApi" v-if="!readonly">Nytt API</v-btn>
                <data-dialog ref="api_dialog" :fields="api_fields" title="API" :readonly="readonly" />
  
                <v-data-table v-if="changed.apis.length"
                    :headers="apiHeaders"
                    :items="changed.apis"
                    :hide-default-header="$root.isSmallScreen"
                    :hide-default-footer="true"
                    sort-by="name"
                    item-value="id"
                    must-sort
                    class="elevation-1"
                    @click:row="apiClick"
                    :items-per-page="1000"
                  >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="apiClick(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="!readonly"
                      small
                      @click.stop="removeApi(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <!-- Funktioner -->
            <v-row>
              <v-col cols=12> 
                <data-dialog ref="function_dialog" title="Ny funktion" :fields="newfunction_fields" />
  
                <v-btn v-if="!readonly" color="primary" @click="newFunction">Ny funktion</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="changed.functions && changed.functions.length">
              <v-col cols=12 md=6>
                <v-select
                  :items="changed.functions"
                  label="Välj funktion"
                  solo
                  v-model="selectedFunction"
                  :item-text="getFunctionName"
                  item-value="id"
                  return-object
                  @change="functionChanged"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="selectedFunction">
              <v-col cols=12 md=6>
                <v-text-field label="Namn" v-model="selectedFunction.name" :readonly="readonly"></v-text-field>
              </v-col>
              <v-col cols=12 md=2>
                <v-select label="Språk" v-model="selectedFunction.language" :items="lambda_languages" item-text="name" item-value="id" :readonly="readonly" @change="functionChanged" item-disabled="isDeprecated"></v-select>
                <div v-if="!validLanguage">
                  <p class="red--text">{{ deprecatedLanguageString }}</p>
                </div>
              </v-col>
              <v-col cols=12 md=2>
                <v-text-field label="Maximal körtid" v-model.number="selectedFunction.timeout" :readonly="readonly"></v-text-field>
              </v-col>
              <v-col cols=12 md=2>
                <v-btn color="secondary" class="ml-4" :disabled="!selectedFunction || readonly" @click="removeFunction">Ta bort funktionen</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="selectedFunction">
              <v-col cols=12>
                <v-tabs v-model="functionTab">
                  <v-tab @change="refreshEditor('functionCode')">Kod</v-tab>
                  <v-tab @change="refreshEditor('functionTestInput')">Test</v-tab>
                  <v-tab>Loggar</v-tab>
                </v-tabs>

                <v-tabs-items v-model="functionTab">
                  <v-tab-item>
                    <CodeEditor ref="functionCode" v-model="selectedFunction.code" :type="selectedFunction.language" />
                  </v-tab-item>

                  <v-tab-item>
                    <v-row>
                      <v-col cols=6>
                        <CodeEditor ref="functionTestInput" v-model="selectedFunction.testInput" type="json" />
                      </v-col>
                      <v-col cols=6>
                        <v-btn color="primary" class="ml-4" @click="testRun" :loading="loading.test" :disabled="!testInputOk">Testa funktionen</v-btn>

                        <div v-if="testOutput.function_id == selectedFunction.id" class="mt-2">
                          <div v-if="testOutput.success">
                              <div><v-icon color="success" class="mr-2">mdi-check</v-icon>Kördes utan fel</div>
                          </div>
                          <div v-else>
                            <div><v-icon color="error" class="mr-2">mdi-alert</v-icon>Kördes med fel</div>
                          </div>
                          <div style="white-space: pre-line" v-text="testOutput.data" class="mt-2"></div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item>
                    <v-row>
                      <v-col cols=12>
                        <v-btn color="primary" class="ml-4" @click="getLogs" :loading="loading.logs">Hämta loggar</v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols=12>
                        <div v-for="message in functionLog" style="white-space: pre-line" :key="message">{{message}}</div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>

              </v-col>
            </v-row>
          </v-tab-item>
  
          <v-tab-item>
            <div class="pa-4">
              <v-row v-if="selectedVersion.review_comments && selectedVersion.review_comments.length">
                <v-col cols=12>
                  <v-timeline>
                    <v-timeline-item
                      v-for="(comment,index) in selectedVersion.review_comments"
                      :left="comment.supplier"
                      :right="!comment.supplier"
                      :key="index"
                      >
                      <template v-slot:icon>
                        <v-avatar>
                          <img :src="comment.imageurl" style="width: 50px; height: 50px" />
                        </v-avatar>
                      </template>
                      <v-card class="pa-4" elevation="5">
                        <b>{{comment.time}}</b><span class="ml-4">{{comment.developer_name}}</span><br><br>
                        <span style="white-space: break-spaces">{{comment.text}}</span>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
  
              <v-row v-if="selectedVersion.state == 'test' && hasReviewErrors()">
                <v-col cols=12>
                  <v-alert :type="error.type" v-for="error in getReviewErrors()" :key="error.id">{{error.text}}</v-alert>
                </v-col>
              </v-row>
  
              <v-row v-if="selectedVersion.state == 'test'">
                <v-col cols=12>
                  <v-textarea
                    v-model="reviewComment"
                    placeholder="Skriv eventuell kommentar till Zoezi:s granskare"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=12>
                  <v-btn color="primary" @click="review" :disabled="readonly || selectedVersion.state != 'test' || addonChanged || hasReviewErrors()">
                    Skicka in för granskning
                  </v-btn>
  
                  <v-btn v-if="selectedVersion.state == 'review'" color="error" @click="cancelReview">
                    Avbryt granskning
                  </v-btn>
                </v-col>
              </v-row>
  
              <v-row v-if="($root.reviewer.approve || $root.reviewer.reject) && selectedVersion.state == 'review'">
                <v-col cols=12>
                  <v-textarea
                    v-model="rejectReason"
                    placeholder="Vid avslag, ange orsak till varför tillvalet inte kan godkännas"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="($root.reviewer.approve || $root.reviewer.reject) && selectedVersion.state == 'review'">
                <v-col cols=12>
                  <v-btn v-if="$root.reviewer.approve" color="primary" @click="approve()" :disabled="rejectReason">Godkänn</v-btn>
  
                  <v-btn v-if="$root.reviewer.reject" color="error" @click="reject()" :disabled="!rejectReason">Avslå</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <div style="height: 60px">&nbsp;</div>
          <v-footer app style="z-index: 6">
        <div v-if="!validLanguage" class="red--text w-12">{{ deprecatedLanguageString }}</div>
            <a class="text-decoration-none" :href="'https://' + (changed.test_hostname || 'developer.zoezi.se') + '/apidoc/index.html'" target="_blank"><v-icon class="mr-2" color="primary">mdi-book-open-variant</v-icon>API-dokumentation</a>
            <a v-if="addon.version.state == 'test' && changed.test_hostname" class="ml-8 text-decoration-none" :href="'https://' + changed.test_hostname" target="_blank">Testsystem: {{changed.test_hostname}}</a>
            <v-switch v-if="addon.version.state == 'test' && changed.test_hostname && loggedInOnTestSystem" :input-value="startedOnTestSystem" class="ml-8" label="Tillvalet tillagt" @change="toggleStarted" :loading="startedOnTestSystem === null" />
            <span v-if="addon.version.state == 'test' && changed.test_hostname && !loggedInOnTestSystem && loggedInOnTestSystem !== null" class="ml-8">Inte inloggad på testsystemet</span>
            <v-spacer></v-spacer>
  
              <v-btn class="ml-2" v-if="addon.version.state == 'live' && $root.reviewer.hotpatch" color="error" :disabled="!addonChanged || !valid || !validLanguage" @click="hotpatch" :loading="loading.hotpatch">
                Hotpatcha
              </v-btn>
            
              <v-btn class="ml-2" v-if="addon.version.state == 'test' || (addon.version.state == 'review' && $root.reviewer.hotpatch)" color="primary" :disabled="!addonChanged || !valid || !validLanguage" @click="save" :loading="loading.save">
                Spara
              </v-btn>
  
              <v-btn class="ml-2" v-if="addon.version.state == 'live'" color="primary" @click="newVersion" :loading="loading.newVersion" :disabled="!validLanguage">
                Skapa ny version
              </v-btn>
          </v-footer>
      </v-form>
    </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import {getAddons, translate, getRandomId, getRandomIdShort, decimalR, categories} from '@/utils.js';
import CodeEditor from '../views/CodeEditor.vue'

let widget_types = [
  {name: 'Personaldashboard', id: 'staffdashboard'},
  {name: 'Kundkort', id: 'customercard'},
  {name: 'Personalkort', id: 'staffcard'}
];

let widget_subtypes = [
  {name: 'Generell', id: 'general'},
  {name: 'Ekonomi', id: 'economy'},
  {name: 'Rapport', id: 'report'}
];

let widget_invocation_types = [
  {name: 'Inbyggd funktion', id: 'function'},
  {name: 'Extern URL', id: 'external'},
  {name: 'Komponent', id: 'component'}
];

let nodejs_template = `let zoezi = require('zoezi');

exports.main = (data, context) => {
    zoezi.init(data.apikey, data.hostname);
    data.events.forEach(e => {
        // Do something
    });

    context.succeed('');
};
`;

let python_template = `import zoezi

def main(data, context):
    zoezi.init(data['apikey'], data['hostname'])
    for e in data['events']:
        # Do something
`;

let python_widget_template = `import zoezi

def main(data, context):
    zoezi.init(data['apikey'], data['hostname'])
    return '<html>Hello world</html>'
`;


let nodejs_widget_template = `let zoezi = require('zoezi');

exports.main = (data, context) => {
    zoezi.init(data.apikey, data.hostname);
    context.succeed('<html>Hello world</html>');
};
`;

let nodejs_api_template = `let zoezi = require('zoezi');

exports.main = (data, context, callback) => {
    zoezi.init(data.apikey, data.hostname);
    let param1 = data.params.param1;
    let param2 = data.params.param2;
    if (param2 == undefined) {
      callback(Error('Missing parameter: param2'), null)
      return;
    }
    callback(null, {result: 'ok'});
};
`;

let python_api_template = `import zoezi
import json

def main(data, context):
    zoezi.init(data['apikey'], data['hostname'])
    param1 = data['params'].get('param1')
    param2 = data['params'].get('param2')
    if not param2:
        raise Exception('Missing parameter: param2')
    return json.dumps({'result': 'ok'})
`;

let nodejs_mailing_template = `let zoezi = require('zoezi');

exports.main = (data, context) => {
  zoezi.init(data.apikey, data.hostname);
  let subject = data.mail.subject;
  let message = data.mail.message;
  context.succeed('');
};
`;

let python_mailing_template = `import zoezi

def main(data, context):
  zoezi.init(data['apikey'], data['hostname'])
  subject = data['mail'].get('subject')
  message = data['mail'].get('message')
`;

let lambda_languages = [
  {name: 'Node.js 20', id: 'nodejs20.x', mode: 'text/javascript', templates: {webhook: nodejs_template, widget: nodejs_widget_template, api: nodejs_api_template, mailing: nodejs_mailing_template}  },
  {name: 'Node.js 18', id: 'nodejs18.x', mode: 'text/javascript', templates: {webhook: nodejs_template, widget: nodejs_widget_template, api: nodejs_api_template, mailing: nodejs_mailing_template}  },
  {name: 'Node.js 16', id: 'nodejs16.x', mode: 'text/javascript', templates: {webhook: nodejs_template, widget: nodejs_widget_template, api: nodejs_api_template, mailing: nodejs_mailing_template}  },
  {name: 'Node.js 14 [Deprecated 22/01/2023]', id: 'nodejs14.x', mode: 'text/javascript', templates: {webhook: nodejs_template, widget: nodejs_widget_template, api: nodejs_api_template, mailing: nodejs_mailing_template}, isDeprecated: true  },
  //{name: 'Node.js 12', id: 'nodejs12.x', mode: 'text/javascript', templates: {webhook: nodejs_template, widget: nodejs_widget_template, api: nodejs_api_template, mailing: nodejs_mailing_template}  },
  {name: 'Python 3.12', id: 'python3.12', mode: 'text/x-python', version: 3, templates: {webhook: python_template, widget: python_widget_template, api: python_api_template, mailing: python_mailing_template } },
  {name: 'Python 3.9', id: 'python3.9', mode: 'text/x-python', version: 3, templates: {webhook: python_template, widget: python_widget_template, api: python_api_template, mailing: python_mailing_template } },
  {name: 'Python 3.8', id: 'python3.8', mode: 'text/x-python', version: 3, templates: {webhook: python_template, widget: python_widget_template, api: python_api_template, mailing: python_mailing_template } },
  //{name: 'Python 2.7', id: 'python2.7', mode: 'text/x-python', version: 2, templates: {webhook: python_template, widget: python_widget_template, api: python_api_template } },
//  {name: 'Ruby 2.7', id: 'ruby2.7', mode: 'text/x-ruby', templates: {webhook: ruby_template, widget: ruby_widget_template } },
  //{name: 'Java 11', id: 'java11', mode: 'text/x-java'},
  //{name: 'Go 1.x', id: 'go1.x', mode: 'text/x-go'}, (requires us to compile the code on backend)
  //{name: '.NET Core 3.1', id: 'dotnetcore3.1', mode: 'text/x-csharp'},
];

let functions_templates = [
  {name: 'Mall för widget', id: 'widget'},
  {name: 'Mall för webhooks', id: 'webhook'},
  {name: 'Mall för API', id: 'api'},
  {name: 'Mall för utskick', id: 'mailing'}
];

let component_types = [
  {id: 'vue2', name: 'Vue 2 (hemsida och admin)', hint: 'För hemsida och adminsida'},
  {id: 'vue3', name: 'Vue 3 (admin)', hint: 'För adminsida. Snabbare, effektivare och fler möjligheter'},
];

export default {
  name: 'showAddonComponent',

  components: {
    CodeEditor
  },

  data: () => ({
    addon: null,
    valid: false,
    events: [],
    permissions: [],
    domains: [],
    chains: [],
    modules: [],
    selectedVersion: null,
    readonly: true,
    tab: null,
    functionTab: null,
    nameRules: [
      v => v.length ? true : 'Tillvalet måste ges ett namn'
    ],
    requiredEmail: [
      v => /.+@.+\..+/.test(v) || 'Ingen giltig e-postadress',
      v => v ? true : 'Värdet måste fyllas i'
    ],
    emailRules: [
      v => !v ? true :  (/.+@.+\..+/.test(v) || 'Ingen giltig e-postadress')
    ],
    requiredRules: [
      v => v ? true : 'Värdet måste fyllas i'
    ],
    hostnamelistRules: [
      v => {
        if (v) {
          let parts = v.split(',');
          for (let i=0; i < parts.length; ++i) {
            let p = parts[i];
            let hparts = p.split('.');
            if (hparts.length != 3 || hparts[2] != 'se' || ['gymsystem', 'zoezi', 'lokalsystem'].indexOf(hparts[1]) == -1) return 'Ingen giltig systemadress';
          }
        }
        return true;
      }
    ],
    decimalRule: [
        v => !v || decimalR.test(v) ? true : 'Inget giltigt decimaltal'
    ],
    changed: {
      name: null,
      image: null,
      image_object: null,
      supplier_mail: null,
      onstart_mail: null,
      onstart_text: null,
      start_fee: null,
      price_per_month: null,
      use_transactions: false,
      free_uses_per_month: null,
      price_per_usage: null,
      price_per_usage_percent: null,
      description: null,
      webhooks: [],
      mailinghandlers: [],
      widgets: [],
      for_all: null,
      hostnames: null,
      test_hostname: null,
      settings: [],
      permissions_granted: [],
      permissions_provided: [],
      modules: [],
      price_rules: [],
      available_rules: [],
      functions: [],
      category: null,
      screenshots: [],
      unique_apikey: false,
      usage_name: null,
      components: [],
      apis: [],
      preview: null
    },
    webhookHeaders: [{text: 'Namn', value: 'name'}, {text: 'Händelser', value: 'events'},  {text: '', value: 'action', sortable: false}],
    showWebhookDialog: false,
    editingWebhookItem: {
      name: '',
      useFunction: true,
      functionName: '',
      url: '',
      events: [],
      actions: []
    },
    editingWebhookItemIndex: -1,
    defaultWebhookItem: {
      name: '',
      useFunction: true,
      functionName: '',
      url: '',
      events: [],
      actions: []      
    },
    webhook_actions: [{id: 'add', name: 'Tillägg'}, {id: 'update', name: 'Uppdatering'}, {id: 'delete', name: 'Borttagning'}],
    widgetHeaders: [{text: 'Namn', value: 'name'}, {text: 'Visas var?', value: 'type'}, {text: '', value: 'action', sortable: false}],
    mailingHandlerHeaders: [{text: 'Namn', value: 'name'}, {text: '', value: 'action', sortable: false}],
    settingsHeaders: [{text: 'Namn', value: 'name'}, {text: '', value: 'action', sortable: false}],
    apiHeaders: [{text: 'Namn', value: 'name'}, {text: 'Path', value: 'path'}, {text: '', value: 'action', sortable: false}],
    settings_fields: [
      {id: 'name', label: 'Namn', required: true, type: 'text'},
      {id: 'type', label: 'Typ', required: true, type: 'select', options: [
        {id: 'text', name: 'Text'},
        {id: 'number', name: 'Tal'},
        {id: 'checkbox', name: 'Kryssruta'}
      ]}
    ],
    permissionsHeaders: [{text: 'Namn', value: 'id'}, {text: '', value: 'action', sortable: false}],
    permissions_fields: [
      {id: 'id', label: 'Namn', required: true, type: 'text'}
    ],
    priceHeaders: [
      {text: 'Kedja', value: 'chain'},
      {text: 'Domän', value: 'domain'},
      {text: 'Startkostnad', value: 'start_fee'},
      {text: 'Pris per månad', value: 'price_per_month'},
      {text: '', value: 'action', sortable: false}
    ],
    priceHeadersTransaction: [
      {text: 'Kedja', value: 'chain'},
      {text: 'Domän', value: 'domain'},
      {text: 'Startkostnad', value: 'start_fee'},
      {text: 'Pris per månad', value: 'price_per_month'},
      {text: 'Antal fria transaktioner per månad', value: 'free_uses_per_month'},
      {text: 'Pris per transaktion', value: 'price_per_usage'},
      {text: 'Procent per transaktion', value: 'price_per_usage_percent'},
      {text: '', value: 'action', sortable: false}
    ],
    price_fields: [
      {id: 'chain', label: 'Kedja', type: 'select', options: [], clearable: true},
      {id: 'domain_id', label: 'Domän', type: 'select', options: []},
      {id: 'start_fee', label: 'Startkostnad', type: 'price'},
      {id: 'price_per_month', label: 'Pris per månad', type: 'price'},
    ],
    price_fields_transaction: [
      {id: 'chain', label: 'Kedja', type: 'select', options: [], clearable: true},
      {id: 'domain_id', label: 'Domän', type: 'select', options: []},
      {id: 'start_fee', label: 'Startkostnad', type: 'price'},
      {id: 'price_per_month', label: 'Pris per månad', type: 'price'},
      {id: 'free_uses_per_month', label: 'Antal fria transaktioner per månad', type: 'number'},
      {id: 'price_per_usage', label: 'Pris per transaktion', type: 'price'},
      {id: 'price_per_usage_percent', label: 'Procent per transaktion', type: 'decimal'}
    ],
    availableHeaders: [
      {text: 'Kedja', value: 'chain'},
      {text: 'Domän', value: 'domain'},
      {text: '', value: 'action', sortable: false}
    ],
    available_fields: [
      {id: 'chain', label: 'Kedja', type: 'select', options: [], clearable: true},
      {id: 'inverted', label: 'För alla andra', type: 'checkbox', visible: 'chain'},
      {id: 'domain_id', label: 'Domän', type: 'select', options: [], visible: '!chain'},
    ],
    widget_fields: [
      {id: 'name', label: 'Namn', type: 'text', required: true},
      {id: 'type', label: 'Visas var?', type: 'select', options: widget_types, required: true },
      {id: 'subtype', label: 'Typ', type: 'select', options: widget_subtypes, required: true, visible: (fields) => fields.find(x => x.id=='type').value == 'staffdashboard'},
      {id: 'invocationType', label: 'Anropas hur?', type: 'select', options: widget_invocation_types, required: true},
      {id: 'functionName', label: 'Funktion', type: 'select', options: [], visible: (fields) => fields.find(x => x.id=='invocationType').value == 'function'},
      {id: 'url', label: 'URL', type: 'url', visible: (fields) => fields.find(x => x.id=='invocationType').value == 'external'},
      {id: 'component', label: 'Komponent', type: 'select', options: [], visible: (fields) => fields.find(x => x.id=='invocationType').value == 'component'},
      {id: 'permission', label: 'Behörighet som krävs för att visa widgeten', type: 'select', options: [], clearable: true },
      {
        id: 'settings',
        label: 'Inställningar',
        ref_dialog: 'widget_permission_list',
        type: 'list',
        newtitle: 'Ny inställning',
        fields: [
          {id: 'title', label: 'Titel', type: 'text', required: true},
          {id: 'name', label: 'Namn på fält (id)', type: 'text', required: true},
          {id: 'required', label: 'Obligatorisk', type: 'checkbox'},
          {id: 'type', label: 'Typ', type: 'select', required: true,  options: [
              {id: 'text', name: 'Text'},
              {id: 'textarea', name: 'Längre text'},
              {id: 'number', name: 'Tal'},
              {id: 'price', name: 'Pris'},
              {id: 'date', name: 'Datum'},
              {id: 'time', name: 'Tid'},
              {id: 'datetime', name: 'Datum och tid'},
              {id: 'checkbox', name: 'Kryssruta'}
            ]
          },
          {id: 'default', label: 'Defaultvärde', type: 'text'},
        ],
        headers: [
          {text: 'Namn', value: 'title'},
          {text: '', value: 'action', sortable: false}
        ]
      }
    ],
    mailinghandler_fields: [
      {id: 'name', label: 'Namn', type: 'text', required: true},
      {id: 'functionName', label: 'Funktion', type: 'select', options: []},
    ],
    api_fields: [
      {id: 'name', label: 'Namn', type: 'text', required: true},
      {id: 'path', label: 'Path', type: 'text', required: true, rule: (v) => !v || v.startsWith('/') ? true : 'Måste börja på /'},
      {id: 'method', label: 'Metod', type: 'select', required: true, default: 'POST', options: [{id: 'GET', name: 'GET'}, {id: 'POST', name: 'POST'}]},
      {id: 'functionName', label: 'Funktion', type: 'select', options: [], required: true},
      {id: 'description', label: 'Beskrivning', type: 'textarea'},
      {id: 'session', label: 'Inloggad användare krävs', type: 'checkbox'},
      {id: 'permission', label: 'Behörighet som krävs för att anropa', type: 'select', options: [], clearable: true },
      {
        id: 'params',
        label: 'Parametrar',
        ref_dialog: 'api_params_list',
        type: 'list',
        newtitle: 'Ny parameter',
        fields: [
          {id: 'name', label: 'Namn på parameter', type: 'text', required: true},
          {id: 'description', label: 'Beskrivning', type: 'textarea'},
          {id: 'required', label: 'Obligatorisk', type: 'checkbox'},
          {id: 'type', label: 'Typ', type: 'select', required: true,  options: [
              {id: 'string', name: 'Sträng'},
              {id: 'integer', name: 'Tal'},
              {id: 'datetime', name: 'Datum och tid'},
              {id: 'date', name: 'Datum'},
              {id: 'boolean', name: 'Boolean'},
              {id: 'array of integer', name: 'Lista med tal'},
              {id: 'json', name: 'JSON'}
            ]
          }
        ],
        headers: [
          {text: 'Namn', value: 'name'},
          {text: '', value: 'action', sortable: false}
        ]
      }
    ],
    rejectReason: null,
    reviewComment: null,
    code: '',
    cmOption: {
        tabSize: 4,
        indentUnit: 4,
        lineNumbers: true,
        matchBrackets: true,
        mode: 'python',
        readOnly: true
    },
    selectedFunction: null,
    selectedComponent: null,
    newfunction_fields: [
      {id: 'name', label: 'Namn', type: 'text', required: true, value: ''},
      {id: 'language', label: 'Språk', type: 'select', required: true, options: lambda_languages.filter(x => !x.isDeprecated)},
      {id: 'template', label: 'Mall', type: 'select', required: true, options: functions_templates},
      {id: 'timeout', label: 'Maximal körtid', type: 'number', value: 3}
    ],
    lambda_languages: lambda_languages,
    component_types: component_types,
    categories: categories.slice(),
    functionLog: [],
    loading: {
      save: false,
      logs: false,
      hotpatch: false,
      image: false,
      screenshot: false,
      newVersion: false,
      review: false,
      cancelreview: false,
      approve: false,
      reject: false,
      test: false,
      gitInit: false
    },
    selectedScreenshot: 0,
    tmp: {
      modules: '',
      permissions_granted: '',
      webhook: ''
    },
    packages: [],
    defaultForPackage: [],
    recommendedForPackage: [],
    startedOnTestSystem: null,
    loggedInOnTestSystem: null,
    compileResult: {
      compiling: false,
      component_id: '',
      result: null
    },
    testOutput: {
      data: '',
      success: false,
      function_id: ''
    },
    showImageDialog: false,
    showScreenshotDialog: false,
    gitRepoStatus: null
  }),
  mounted() {
    this.$root.getDeveloper().then(() => {
      this.updateAddon();
      this.getSupplierData();
    });

    document.addEventListener("keydown", this.keydown);

    fetch('/api/package/get').then(response => response.json() ).then(data => {
      if (!_.isArray(data)) {
        this.defaultForPackageOrig = [];
        this.recommendedForPackageOrig = [];
        return;
      }

      this.packages = data.slice();

      const addon_id = this.$route.params.addon_id;
      if(addon_id) {
        const addons = data.filter(p => {
          return p.addons && p.addons.includes(addon_id);
        }).map(p => p.name);
        const recommended = data.filter(p => {
          return p.recommended && p.recommended.includes(addon_id);
        }).map(p => p.name);

        this.defaultForPackage = addons.slice();
        this.defaultForPackageOrig = addons.sort();
        this.recommendedForPackage = recommended.slice();
        this.recommendedForPackageOrig = recommended.sort();
      }

    });
  },
  computed: {
    deprecatedLanguages() {
      return this.lambda_languages.filter(x => x.isDeprecated).map(x => x.id);
    },
    validLanguage() {
      for (const fun of this.changed.functions) {
        if (this.deprecatedLanguages.includes(fun.language)) {
          return false;
        }
      }
      return true;
    },
    deprecatedLanguageString() {
      const invalidFunctions = this.changed.functions.filter(fun => this.deprecatedLanguages.includes(fun.language));
      const invalidString = invalidFunctions.map(fun => `${fun.name} language: ${lambda_languages.find(x => x.id == fun.language).name}`).join(' ');

      return `The following function(s) use deprecated language(s): ${invalidString}. The addon will continue working, but to make any changes, you must upgrade to a newer version or switch to a supported language.`;
    },
    addonChanged() {

      function diff(orig, data) {
        if(orig.length !== data.length) return true;
        data = data.slice().sort();
        for(let i=0; i<orig.length; i++)
          if(orig[i] != data[i]) return true;
        return false;
      }

      if(diff(this.defaultForPackageOrig, this.defaultForPackage)) return true;
      if(diff(this.recommendedForPackageOrig, this.recommendedForPackage)) return true;
      return JSON.stringify(this.getSubmitItem(false)) != JSON.stringify(this.getOriginalItem());
    },
    sortedComponents() {
      return this.changed.components.sort((a,b) => a.name.localeCompare(b.name));
    },
    canRemove() {
      return this.$root.reviewer.hotpatch;
    },
    testInputOk() {
      if (!this.selectedFunction) {
        return false;
      }

      try {
        JSON.parse(this.selectedFunction.testInput);
        return true;
      } catch {
        return false;
      }      
    },
    git() {
      return window.localStorage.getItem('git');
    }
  },
  methods: {
    widgetType(type) {
      return _.find(widget_types, {id: type}).name;
    },
    updateAddon() {
      getAddons().then(data => {
        this.addon = data.find(x => x.id == this.$route.params.addon_id);
        if (!this.addon) {
          fetch('/api/addon/review/get').then(response => response.json() ).then(data => {
            this.addon = data.find(x => x.id == this.$route.params.addon_id);
            this.addon.version = _.find(this.addon.versions, {state: 'review'}) || _.find(this.addon.versions, {state: 'test'}) || _.find(this.addon.versions, {state: 'live'});
            this.selectedVersion = this.addon.version;
            this.versionSelected();
          });
        } else {
          this.selectedVersion = this.addon.version;
          this.versionSelected();
        }
      });
    },
    getSupplierData() {
      fetch('/api/addon/supplier/data/get').then(response => response.json()).then(data => {
        this.events = data.events.sort((a, b) => this.getEventName(a).localeCompare(this.getEventName(b)))
        this.permissions = data.permissions.sort((a, b) => this.getPermissionName(a).localeCompare(this.getPermissionName(b)));
        this.permissions = this.permissions.map((p) => {
          return {id: p.id, name: this.getPermissionName(p)};
        });
        this.domains = data.domains.sort((a, b) => this.getDomainName(a).localeCompare(this.getDomainName(b)));
        this.chains = data.chains.sort((a, b) => this.getChainName(a).localeCompare(this.getChainName(b)));

        _.find(this.widget_fields, {id: 'permission'}).options = this.permissions;
        _.find(this.price_fields, {id: 'domain_id'}).options = this.domains;
        _.find(this.price_fields, {id: 'domain_id'}).getName = this.getDomainName;
        _.find(this.price_fields, {id: 'chain'}).options = this.chains;

        _.find(this.price_fields_transaction, {id: 'domain_id'}).options = this.domains;
        _.find(this.price_fields_transaction, {id: 'domain_id'}).getName = this.getDomainName;
        _.find(this.price_fields_transaction, {id: 'chain'}).options = this.chains;

        _.find(this.available_fields, {id: 'domain_id'}).options = this.domains;
        _.find(this.available_fields, {id: 'domain_id'}).getName = this.getDomainName;
        _.find(this.available_fields, {id: 'chain'}).options = this.chains;

        _.find(this.api_fields, {id: 'permission'}).options = this.permissions;

        this.modules = data.modules.sort((a, b) => this.getModuleName(a).localeCompare(this.getModuleName(b)));
      });
    },
    review() {
      this.loading.review = true;
      let data = {id: this.selectedVersion.id, comment: this.reviewComment};
      fetch('/api/addon/supplier/review', {method: 'POST', body: JSON.stringify(data)}).then(() => {
        this.updateAddon();
        this.loading.review = false;
      });
    },
    cancelReview() {
      this.loading.cancelreview = true;
      let data = {id: this.selectedVersion.id};
      fetch('/api/addon/supplier/cancelreview', {method: 'POST', body: JSON.stringify(data)}).then(() => {
        this.updateAddon();
        this.loading.cancelreview = false;
      });
    },
    getSubmitItem(all=true) {
      let data = {id: this.selectedVersion.id, name: this.changed.name,
          supplier_mail: this.changed.supplier_mail, start_fee: this.changed.start_fee, price_per_month: this.changed.price_per_month,
          onstart_mail: this.changed.onstart_mail,
          free_uses_per_month: this.changed.free_uses_per_month,
          price_per_usage: this.changed.price_per_usage, price_per_usage_percent: this.changed.price_per_usage_percent,
          description: this.changed.description,
          webhooks: this.changed.webhooks,
          mailinghandlers: this.changed.mailinghandlers,
          widgets: this.changed.widgets,
          onstart_text: this.changed.onstart_text,
          test_hostname: this.changed.test_hostname,
          settings: this.changed.settings, permissions_granted: this.changed.permissions_granted, modules: this.changed.modules,
          permissions_provided: this.changed.permissions_provided,
          price_rules: this.changed.price_rules,
          available_rules: this.changed.for_all ? [] : this.changed.available_rules,
          functions: this.changed.functions,
          apis: this.changed.apis,
          components: this.changed.components,
          category: this.changed.category || null,
          screenshots: this.changed.screenshots,
          image: this.changed.image,
          unique_apikey: this.changed.unique_apikey,
          usage_name: this.changed.usage_name,
          last_modified: this.selectedVersion.last_modified, // We always provide the date of when the loaded version was last changed
          preview: this.changed.preview
        };
        if(all) {
          data.defaultForPackage = this.defaultForPackage;
          data.recommendedForPackage = this.recommendedForPackage;
        }
        return data;
    },
    getOriginalItem() {
      let data = {id: this.selectedVersion.id, name: this.selectedVersion.name,
          supplier_mail: this.selectedVersion.supplier_mail, start_fee: this.selectedVersion.start_fee, price_per_month: this.selectedVersion.price_per_month,
          onstart_mail: this.selectedVersion.onstart_mail,
          free_uses_per_month: this.selectedVersion.free_uses_per_month,
          price_per_usage: this.selectedVersion.price_per_usage, price_per_usage_percent: this.selectedVersion.price_per_usage_percent,
          description: this.selectedVersion.description,
          webhooks: this.selectedVersion.webhooks || [],
          mailinghandlers: this.selectedVersion.mailinghandlers || [],
          widgets: this.selectedVersion.widgets || [],
          onstart_text: this.selectedVersion.onstart_text,
          test_hostname: this.selectedVersion.test_hostname,
          settings: this.selectedVersion.settings || [], permissions_granted: this.selectedVersion.permissions_granted || [], modules: this.selectedVersion.modules || [],
          permissions_provided: this.selectedVersion.permissions_provided || [],
          price_rules: this.selectedVersion.price_rules || [],
          available_rules: this.selectedVersion.available_rules || [],
          functions: this.selectedVersion.functions || [],
          apis: this.selectedVersion.apis || [],
          components: this.selectedVersion.components || [],
          category: this.selectedVersion.category,
          screenshots: this.selectedVersion.screenshots || [],
          image: this.selectedVersion.image,
          unique_apikey: !!this.selectedVersion.unique_apikey,
          usage_name: this.selectedVersion.usage_name,
          last_modified: this.selectedVersion.last_modified,
          preview: this.selectedVersion.preview
        };
        return data;
    },
    save() {
      if (!this.validLanguage) {
        throw new Error('ADDON PORTAL: Calling save() with invalid lambda language')
      }

      this.loading.save = true;
      fetch('/api/addon/supplier/version/change', {method: 'POST', body: JSON.stringify(this.getSubmitItem())}).then((response) => {
        if (response.status == 200) {
          this.updateAddon();
          this.loading.save = false;
        } else {
          response.json().then(x => {
            this.$root.showErrorDialog('Fel', x.message);
            this.loading.save = false;
          });
        }
      });
    },
    hotpatch() {
      if (!this.validLanguage) {
        throw new Error('ADDON PORTAL: Calling hotpatch() with invalid lambda language')
      }

      this.loading.hotpatch = true;
      fetch('/api/addon/supplier/version/hotpatch', {method: 'POST', body: JSON.stringify(this.getSubmitItem())}).then((response) => {
        if (response.status == 200) {
          this.updateAddon();
          this.loading.hotpatch = false;
        } else {
          response.json().then(x => {
            this.$root.showErrorDialog('Fel', x.message);
            this.loading.hotpatch = false;
          });
        }
      });
    },
    removeVersion() {
      let data = {id: this.selectedVersion.id};
      fetch('/api/addon/supplier/version/remove', {method: 'POST', body: JSON.stringify(data)}).then(() => {
        this.updateAddon();
      });
    },
    getState(state) {
      switch (state) {
        case 'test': return 'Under utveckling';
        case 'review': return 'Granskas';
        case 'live': return 'Live';
      }
    },
    getVersionName(version) {
      return 'Version ' + version.version_number + ' - ' + this.getState(version.state);
    },
    versionSelected() {
      this.changed.name = this.selectedVersion.name;
      this.changed.image = this.selectedVersion.image;
      this.changed.supplier_mail = this.selectedVersion.supplier_mail;
      this.changed.onstart_mail = this.selectedVersion.onstart_mail;
      this.changed.start_fee = this.selectedVersion.start_fee;
      this.changed.price_per_month = this.selectedVersion.price_per_month;
      this.changed.use_transactions = Boolean(Number(this.selectedVersion.price_per_usage) || Number(this.selectedVersion.price_per_usage_percent));
      this.changed.usage_name = this.selectedVersion.usage_name || 'transaktion,transaktioner';
      this.changed.free_uses_per_month = this.selectedVersion.free_uses_per_month;
      this.changed.price_per_usage = this.selectedVersion.price_per_usage;
      this.changed.price_per_usage_percent = this.selectedVersion.price_per_usage_percent;
      this.changed.description = this.selectedVersion.description;
      this.changed.onstart_text = this.selectedVersion.onstart_text;
      this.changed.webhooks = _.cloneDeep(this.selectedVersion.webhooks || []);
      this.changed.mailinghandlers = _.cloneDeep(this.selectedVersion.mailinghandlers || []);
      this.changed.widgets = _.cloneDeep(this.selectedVersion.widgets || []);
      this.changed.for_all = !this.selectedVersion.available_rules || this.selectedVersion.available_rules.length == 0;
      this.changed.available_rules = _.cloneDeep(this.selectedVersion.available_rules || []);
      this.changed.test_hostname = this.selectedVersion.test_hostname;
      this.changed.settings = _.cloneDeep(this.selectedVersion.settings || []);
      this.changed.permissions_granted = _.cloneDeep(this.selectedVersion.permissions_granted || []);
      this.changed.permissions_provided = _.cloneDeep(this.selectedVersion.permissions_provided || []);
      this.changed.modules = _.cloneDeep(this.selectedVersion.modules || []);
      this.changed.price_rules = _.cloneDeep(this.selectedVersion.price_rules || []);
      this.changed.category = this.selectedVersion.category;
      this.readonly = this.selectedVersion.state != 'test' && !this.$root.reviewer.hotpatch;
      this.reviewComment = null;
      this.changed.screenshots = _.cloneDeep(this.selectedVersion.screenshots || []);
      this.changed.functions = _.cloneDeep(this.selectedVersion.functions || []);
      this.changed.components = _.cloneDeep(this.selectedVersion.components || []);
      this.changed.apis = _.cloneDeep(this.selectedVersion.apis || []);
      this.changed.preview = this.selectedVersion.preview;
      this.unique_apikey = this.selectedVersion.unique_apikey;

      if (this.changed.functions.length) {
        this.changed.functions.forEach(f => {
          if (!f.id || f.id.length != 64) {
            f.id = getRandomId();
          }
          if (f.testInput === undefined) {
            f.testInput = '';
          }
        });
        let f = null;
        if (this.selectedFunction) {
          f = _.find(this.changed.functions, {name: this.selectedFunction.name})
        }
        if (!f) {
          f = this.changed.functions[0];
        }
        this.selectedFunction = f;
        this.functionChanged();
      } else {
        this.selectedFunction = null;
      }

      if (this.changed.components.length) {
        this.changed.components.forEach(c => {
          if (!c.type) {
            c.type = 'vue2';
          }
        })
        let c = null;
        if (this.selectedComponent) {
          c = _.find(this.changed.components, {id: this.selectedComponent.id})
        }
        if (!c) {
          c = this.changed.components[0];
        }
        this.selectedComponent = c;
      } else {
        this.selectedComponent = null;
      }

      if (this.changed.widgets.length) {
        this.changed.widgets.forEach(widget => {
          if (widget.useFunction !== undefined) {
            widget.invocationType = widget.useFunction ? 'function' : 'external';
            delete widget.useFunction;
          }
        })
      }

      if (this.selectedVersion.state == 'test') {
        if (!this.changed.supplier_mail) {
          this.changed.supplier_mail = this.$root.supplier.mail;
        }
      }

      this.getGitRepoStatus();
    },
    newVersion() {
      if (!this.validLanguage) {
        throw new Error('ADDON PORTAL: Calling newVersion() with invalid lambda language')
      }

      this.loading.newVersion = true;
      let data = {id: this.addon.id};
      fetch('/api/addon/supplier/version/add', {method: 'POST', body: JSON.stringify(data)}).then(() => {
        this.updateAddon();
        this.loading.newVersion = false;
      });
    },
    newFunction() {
      let self = this;
      this.$refs.function_dialog.show().then(function(f) {
        let template = _.find(lambda_languages, {id: f.language}).templates[f.template];
        f.code = template || '';
        f.id = getRandomId();
        f.testInput = '';
        self.changed.functions.push(f);
        self.selectedFunction = f;
        self.functionChanged();
      });
    },
    getFunctionName(f) {
      return f.name;
    },
    removeFunction() {
      this.$refs.confirmremove.show('Vill du verkligen ta bort funktionen?').then(() => {
        this.changed.functions.splice(this.changed.functions.indexOf(this.selectedFunction), 1);
        this.selectedFunction = this.changed.functions.length ? this.changed.functions[0] : null;
        this.functionChanged();
      });
    },
    functionChanged() {
      if (this.selectedFunction) {
        let ll = _.find(lambda_languages, {id: this.selectedFunction.language});
        this.cmOption.mode = ll.mode;
        this.cmOption.version = ll.version;
        this.cmOption.readOnly = this.readonly;
      }

      this.functionLog = [];
    },
    newComponent() {
      let c = {
        id: getRandomId(),
        name: 'Ny komponent',
        template: '<div>{{text}}</div>',
        code: `{
    zoezi: {
      icon: 'mdi-sheep'
    },
    props: {
      text: {
        title: 'Text',
        type: String,
        default: 'Hej!'
      }
    },
    data: () => ({
    }),
    methods: {
    }
}`,
        css: '',
        dependencies: '',
        type: 'vue2'
      };

      this.changed.components.push(c);
      this.selectedComponent = c;
    },
    removeComponent() {
      this.$refs.confirmremove.show('Vill du verkligen ta bort komponenten?').then(() => {
        this.changed.components.splice(this.changed.components.indexOf(this.selectedComponent), 1);
        this.selectedComponent = this.changed.components.length ? this.changed.components[0] : null;
      });
    },
    getLogs() {
      this.functionLog = [];
      this.loading.logs = true;
      fetch('/api/addon/supplier/function/logs/get?version_id=' + this.selectedVersion.id + '&function_id=' + this.selectedFunction.id).then((response) => response.json().then(x => { this.functionLog = x; this.loading.logs = false; }));
    },
    webhookClick(item) {
      this.showWebhookDialog = true;
      this.editingWebhookItem = Object.assign({}, item);
      this.editingWebhookItemIndex = this.changed.webhooks.indexOf(item);
    },
    closeWebhook() {
      this.showWebhookDialog = false;
        setTimeout(() => {
          this.editingWebhookItem = Object.assign({}, this.defaultWebhookItem)
          this.editingWebhookItemIndex = -1
        }, 300)
    },
    saveWebhook() {
      if (this.editingWebhookItemIndex > -1) {
          Object.assign(this.changed.webhooks[this.editingWebhookItemIndex], this.editingWebhookItem)
        } else {
          this.changed.webhooks.push(this.editingWebhookItem)
        }
        this.closeWebhook()
    },
    getEventName(event) {
      return translate(event.name) + ' (' + event.id + ')';
    },
    getPermissionName(permission) {
      return translate(permission.id);
    },
    getModuleName(module) {
      return translate(module.name);
    },
    getChainName(chain) {
      return chain.name;
    },
    getDomainNameFromId(domain_id) {
      let d = _.find(this.domains, (x) => x.id == domain_id);
      return d && d.name;
    },
    getDomainName(domain) {
      return domain.name + ' (' + domain.hostname + ')';
    },
    removeWebhook(webhook) {
      this.$refs.confirmremove.show('Vill du verkligen ta bort webhooket?').then(() => {
        let i = this.changed.webhooks.indexOf(webhook);
        this.changed.webhooks.splice(i, 1);
        this.closeWebhook();
      });
    },
    widgetClick(item) {
      let self = this;
      let i = this.changed.widgets.indexOf(item);
      _.find(this.widget_fields, {id: 'functionName'}).options = this.changed.functions;
      _.find(this.widget_fields, {id: 'component'}).options = this.changed.components;
      this.$refs.widget_dialog.show(item).then(function(value) {
        Vue.set(self.changed.widgets, i, Object.assign({}, self.changed.widgets[i], value));
      })
    },
    newWidget() {
      let self = this;
      _.find(this.widget_fields, {id: 'functionName'}).options = this.changed.functions;
      _.find(this.widget_fields, {id: 'component'}).options = this.changed.components;

      this.$refs.widget_dialog.show().then(function(value) {
        value.id = getRandomId();
        self.changed.widgets.push(value);
      });
    },
    removeWidget(widget) {
      this.$refs.confirmremove.show('Vill du verkligen ta bort widgeten?').then(() => {
        let i = this.changed.widgets.indexOf(widget);
        this.changed.widgets.splice(i, 1);
      });
    },
    mailingHandlerClick(item) {
      let i = this.changed.mailinghandlers.indexOf(item);
      _.find(this.mailinghandler_fields, {id: 'functionName'}).options = this.changed.functions;
      this.$refs.mailinghandler_dialog.show(item).then((value) => {
        Vue.set(this.changed.mailinghandlers, i, Object.assign({}, this.changed.mailinghandlers[i], value));
      });
    },
    newMailingHandler() {
      _.find(this.mailinghandler_fields, {id: 'functionName'}).options = this.changed.functions;

      this.$refs.mailinghandler_dialog.show().then((value) => {
        value.id = getRandomIdShort();
        this.changed.mailinghandlers.push(value);
      })
    },
    removeMailingHandler(item) {
      this.$refs.confirmremove.show('Vill du verkligen ta bort utskickshanteraren?').then(() => {
        let i = this.changed.mailinghandlers.indexOf(item);
        this.changed.mailinghandlers.splice(i, 1);
      });
    },
    apiClick(item) {
      let i = this.changed.apis.indexOf(item);
      _.find(this.api_fields, {id: 'functionName'}).options = this.changed.functions;
      this.$refs.api_dialog.show(item).then((value) => Vue.set(this.changed.apis, i, Object.assign({}, this.changed.apis[i], value)));
    },
    newApi() {
      _.find(this.api_fields, {id: 'functionName'}).options = this.changed.functions;
      this.$refs.api_dialog.show().then((value) => this.changed.apis.push(value));
    },
    removeApi(api) {
      this.$refs.confirmremove.show('Vill du verkligen ta bort API:et?').then(() => {
        let i = this.changed.apis.indexOf(api);
        this.changed.apis.splice(i, 1);
      });
    },
    settingsClick(item) {
      let self = this;
      let i = this.changed.settings.indexOf(item);
      this.$refs.settings_dialog.show(item).then(function(value) {
        Vue.set(self.changed.settings, i, Object.assign({}, self.changed.settings[i], value));
      })
    },
    newSetting() {
      let self = this;
      this.$refs.settings_dialog.show().then(function(value) {
        self.changed.settings.push(value);
      });
    },
    removeSetting(item) {
      this.$refs.confirmremove.show('Vill du verkligen ta bort inställningen?').then(() => {
        let i = this.changed.settings.indexOf(item);
        this.changed.settings.splice(i, 1);
      });
    },
    permissionsClick(item) {
      let self = this;
      let i = this.changed.permissions_provided.indexOf(item);
      this.$refs.permissions_dialog.show(item).then(function(value) {
        Vue.set(self.changed.permissions_provided, i, Object.assign({}, self.changed.permissions_provided[i], value));
      })
    },
    newPermission() {
      let self = this;
      this.$refs.permissions_dialog.show().then(function(value) {
        self.changed.permissions_provided.push(value);
      });
    },
    removePermission(item) {
      this.$refs.confirmremove.show('Vill du verkligen ta bort behörigheten?').then(() => {
        let i = this.changed.permissions_provided.indexOf(item);
        this.changed.permissions_provided.splice(i, 1);
      });
    },
    priceRuleClick(item) {
      let self = this;
      let i = this.changed.price_rules.indexOf(item);
      this.$refs.price_dialog.show(item).then(function(value) {
        Vue.set(self.changed.price_rules, i, Object.assign({}, self.changed.price_rules[i], value));
      })
    },
    newPriceRule() {
      let self = this;
      this.$refs.price_dialog.show().then(function(value) {
        self.changed.price_rules.push(value);
      });
    },
    removePriceRule(item) {
      this.$refs.confirmremove.show('Vill du verkligen ta bort prisregeln?').then(() => {
        let i = this.changed.price_rules.indexOf(item);
        this.changed.price_rules.splice(i, 1);
      });
    },
    availableRuleClick(item) {
      let self = this;
      let i = this.changed.available_rules.indexOf(item);
      this.$refs.available_dialog.show(item).then(function(value) {
        Vue.set(self.changed.available_rules, i, Object.assign({}, self.changed.available_rules[i], value));
      })
    },
    newAvailableRule() {
      let self = this;
      this.$refs.available_dialog.show().then(function(value) {
        self.changed.available_rules.push(value);
      });
    },
    removeAvailableRule(item) {
      this.$refs.confirmremove.show('Vill du verkligen ta bort tillgänglighetsregeln?').then(() => {
        let i = this.changed.available_rules.indexOf(item);
        this.changed.available_rules.splice(i, 1);
      });
    },
    onImageChanged(file) {
      this.loading.image = true;
      const fr = new FileReader ()
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        let data = {image: fr.result};
        fetch('/api/addon/supplier/image/upload', {method: 'POST', body: JSON.stringify(data) }).then(response => {
          response.json().then(image => {
            this.changed.image = image.url;
            this.$refs.iuploader.value = null;
            this.loading.image = false;
          })
        });
      })
    },
    onScreenshot(file) {
      this.loading.screenshot = true;
      const fr = new FileReader ()
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        let data = {image: fr.result};
        fetch('/api/addon/supplier/image/upload', {method: 'POST', body: JSON.stringify(data) }).then(response => {
          response.json().then(image => {
            this.changed.screenshots.push(image.url);
            this.selectedScreenshot = this.changed.screenshots.length - 1;
            this.$refs.uploader.value = null;
            this.loading.screenshot = false;
          })
        });
      })
    },
    approve() {
      this.loading.approve = true;
      let data = {id: this.addon.version.id };
      fetch('/api/addon/review/approve', {method: 'POST', body: JSON.stringify(data) }).then(() => {
        this.$router.push({name: 'review'});
        this.loading.approve = false;
      });
    },
    reject() {
      this.loading.reject = true;
      let data = {id: this.addon.version.id, reason: this.rejectReason };
      fetch('/api/addon/review/reject', {method: 'POST', body: JSON.stringify(data) }).then(() => {
        this.$router.push({name: 'review'});
        this.loading.reject = false;
      });
    },
    useTransactionsChange() {
      if (!this.changed.use_transactions) {
        this.changed.free_uses_per_month = null;
        this.changed.price_per_usage = null;
        this.changed.price_per_usage_percent = null;
      }
    },
    getTestSystems() {
      if (this.$root.supplier.id != this.addon.supplier_id) {
        return [{id: this.changed.test_hostname, name: this.changed.test_hostname}];
      }

      return (this.$root.supplier.test_hostnames || '').split(',').map(function(name) {
        return {id: name, name: name};
      });
    },
    removeImage() {
      this.changed.image = null;
    },
    removeScreenshot() {
      this.changed.screenshots.splice(this.selectedScreenshot, 1);
    },
    getReviewErrors() {
      let c = this.changed;
      let errors = [
        {id: 'unsaved', type: 'warning', text: 'Det finns osparade ändringar', check: () => this.addonChanged },
        {id: 'description', type: 'error', text: 'Beskrivning av tillvalet saknas', check: () => !c.description || !c.description.length },
        {id: 'supplier_mail', type: 'error', text: 'E-postadress till support saknas', check: () => !c.supplier_mail || !c.supplier_mail.length },
        {id: 'category', type: 'error', text: 'Produktkategori saknas', check: () => !c.category || !c.category.length },
        {id: 'image', type: 'error', text: 'Tillvalet saknar en bild', check: () => !c.image },
      ];

      return errors.filter(e => e.check() );
    },
    hasReviewErrors() {
      return this.getReviewErrors().length > 0;
    },
    getUsageName(count) {
      let index = count == 1 ? 0 : 1;
      let name = (this.changed.usage_name || 'transaktion,transaktioner');
      let names = name.split(',');
      if (index > names.length-1) {
        index = 0;
      }
      return names[index];
    },
    keydown(e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return;
      }
      e.preventDefault();

      if (this.readonly || !this.addonChanged) {
        return;
      }

      this.save();
    },
    checkIfStarted() {
      this.startedOnTestSystem = null;
      if (this.changed && this.changed.test_hostname) {
        let protocol = location.protocol;
        fetch(`${protocol}//${this.changed.test_hostname}/api/addon/shop/get`, {credentials: 'include'}).then(response => {
          response.json().then(data => {
            let addon = data.find(x => x.id == this.addon.id);
            this.loggedInOnTestSystem = addon && (addon.can_add || addon.can_remove);
            this.startedOnTestSystem = addon && addon.started;
          })
        })
      }
    },
    toggleStarted(started) {
      this.startedOnTestSystem = null;
      let data = {id: this.addon.id};
      let protocol = location.protocol;
      fetch(`${protocol}//${this.changed.test_hostname}/api/addon/shop/${started ? 'add' : 'remove'}`, {method: 'POST', credentials: 'include', body: JSON.stringify(data)}).then(response => {
        this.checkIfStarted();
      });
    },
    compile(component) {
      this.compileResult.compiling = true;
      this.compileResult.component_id = null;
      this.compileResult.result = null;
      fetch(`/api/addon/supplier/component/compile?addonversion_id=${this.selectedVersion.id}&component_id=${component.id}`, {method: 'POST'}).then(response => {
        if (response.status != 200) {
          this.$root.showErrorDialog('Fel', 'Något gick fel vid kompileringen');
          this.compileResult.compiling = false;
        } else {
          response.json().then(data => {
            this.compileResult.component_id = component.id;
            this.compileResult.result = data;
            this.compileResult.compiling = false;
          });
        }
      });
    },
    refreshEditor(kind) {
      if (this.$refs[kind] && this.$refs[kind].refreshEditor) {
        this.$refs[kind].refreshEditor();
      }
    },
    remove() {
      this.$refs.confirmremove.show('Vill du verkligen ta bort tillvalet?').then(() => {
         fetch('/api/addon/remove?id=' + this.addon.id, {method: 'POST' }).then(() => {
           this.$router.push({name: 'review'});
        });
      });
    },
    testRun() {
      let function_id = this.selectedFunction.id;
      this.testOutput.function_id = null;
      this.loading.test = true;
      let data = JSON.parse(this.selectedFunction.testInput || '{}');
      fetch('/api/addon/supplier/function/test?version_id=' + this.selectedVersion.id + '&function_id=' + function_id, {method: 'POST', credentials: 'include', body: JSON.stringify(data)}).then((response) => response.json().then(x => {
        this.testOutput.data = x.data;
        this.testOutput.success = x.success;
        this.testOutput.function_id = function_id;
        this.loading.test = false;
      }));
    },
    gitInit() {
      this.loading.gitInit = true;
      fetch('/api/addon/supplier/git/init?addon_id=' + this.addon.id, {method: 'POST'}).then(() => {
        this.getGitRepoStatus();
        this.loading.gitInit = false;
      });
    },
    getGitRepoStatus() {
      fetch('/api/addon/supplier/git/status?addon_id=' + this.addon.id).then(response => response.json()).then(data => {
        this.gitRepoStatus = data;
      });
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keydown);
  },
  watch: {
    selectedVersion() {
      this.checkIfStarted();
    },
    'selectedComponent.type'() {
       if (!this.selectedComponent || !this.selectedComponent.code) {
         return;
       }
       
       if (this.selectedComponent.code.startsWith('{')) {
         this.selectedComponent.code = 'export default ' + this.selectedComponent.code;
       }
    }
  }
}
</script>

<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{title}}</span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid" @submit="_save" :ref="formref">
          <formfield v-for="field in fields" :key="field.id" :field="field" :readonly="readonly" :fields="fields" />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="_close">{{$translate('Cancel')}}</v-btn>
        <v-btn color="primary" text type="submit" :disabled="readonly || !valid" @click="_save">{{$translate('Save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'data-dialog',
  props: ['title', 'readonly', 'fields'],
  data: function () {
    return {
      showDialog: false,
      valid: false,
      resolve: null,
      formref: null
    }
  },
  mounted() {
    this.formref = Math.random().toString(36).substring(7);
  },
  watch: {
    showDialog(val) {
      if (!val) {
        this.$refs[this.formref].reset();
      }
    }
  },
  methods: {
    reset(item) {
      for (let i=0; i < this.fields.length; ++i) {
        let f = this.fields[i];
        let value = item ? item[f.id] : (f.initial !== undefined ? f.initial : null);
        if (f.type == 'list' && !value) {
          value = [];
        }
        Vue.set(f, 'value', value);
      }
    },
    getSubmitItem() {
      let res = {};
      for (let i=0; i < this.fields.length; ++i) {
        let f = this.fields[i];
        res[f.id] = f.value;
      }
      if (this.id) {
        res.id = this.id;
      }
      return res;
    },
    show(item) {
      this.reset(item);
      this.showDialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    _save() {
      if (!this.valid) {
        return;
      }
      this.showDialog = false;
      this.resolve(this.getSubmitItem());
    },
    _close() {
      this.showDialog = false;
    }
  }
}
</script>

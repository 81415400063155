import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

let sv = {
  noDataText: 'Ingen data',
  dataTable: {
    ariaLabel: {
      sortNone: '',
      sortAscending: '',
      sortDescending: '',
      activateAscending: '',
      activateDescending: ''
    }
  },
  carousel: {
    prev: '',
    next: '',
    ariaLabel: {
      delimiter: ''
    }
  },
  dataIterator: {
    noResultsText: 'Inga sökresultat'
  }
};

export default new Vuetify({
  lang: {
    locales: {sv},
    current: 'sv'
  },
  breakpoint: {
    mobileBreakpoint: 'sm'
  }
});

<template>
  <div>
    <v-row>
      <v-col cols=12 md=4>
        <v-form v-model="valid" ref="setpasswordform" @submit.prevent="callSetPassword">
          <v-alert v-if="error" type="error">{{error | translate}}</v-alert>
          <v-text-field label="Lösenord" type="password" v-model="password" />
          <v-text-field label="Bekräfta lösenord" type="password" v-model="second"/>

          <v-btn type="submit" color="primary" :disabled="password.length < 6 || password != second" :loading="loading">Sätt lösenord och logga in</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'setPasswordComponent',
  data: () => ({
    valid: false,
    password: '',
    second: '',
    loading: false,
    error: ''
  }),
  methods: {
    callSetPassword() {
      this.loading = true;
      fetch('/api/memberapi/setPassword?hash=' + this.$route.params.hash + '&newPassword=' + encodeURIComponent(this.password), {method: 'POST'}).then(response => {
        if (response.status == 200) {
          response.json().then(setpwddata => {
            fetch('/api/v8.0/memberapi/login', {method: 'POST', body: JSON.stringify({login: setpwddata.mail, password: this.password })}).then(() => {
              this.$root.refetchDeveloper();
              this.$router.push({name: 'list'});
            });
          });
        } else {
          this.loading = false;
          response.json().then(error => this.error = error && error.message);
        }
      });
    }
  }
}
</script>

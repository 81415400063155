
import Vue from 'vue'
import router from './router'
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import _ from 'lodash';
import confirmRemove from '@/components/confirmRemove.vue';
import formfield from '@/components/formfield.vue';
import dataDialog from '@/components/dataDialog.vue';
import previewComponent from '@/components/preview.vue';
import packagegreetinglistComponent from '@/components/packagegreetinglist.vue';
import packagegreetingeditcardComponent from '@/components/packagegreetingeditcard.vue';
import {fetchLanguageFile} from '@/utils.js';
import '@/codemirror.plugin.js';
import '@/echarts.plugin.js';
import confirmDialog from '@/components/confirmDialog.vue';
import Translate from './Translate.js'


Vue.component(confirmRemove.name, confirmRemove);
Vue.component(formfield.name, formfield);
Vue.component(dataDialog.name, dataDialog);
Vue.component(previewComponent.name, previewComponent);
Vue.component(packagegreetinglistComponent.name, packagegreetinglistComponent);
Vue.component(packagegreetingeditcardComponent.name, packagegreetingeditcardComponent);
Vue.component(confirmDialog.name, confirmDialog);
Vue.use(Translate);

Vue.config.productionTip = false


new Vue({
  el: '#app',
  router,
  vuetify,
  data: () => ({
    developer: null,
    developer_promise: null,
    suppliers: [],
    supplier: null,
    reviewer: false,
    drawer: null,
    showNoDeveloperDialog: false,
    errorDialog: {
      show: false,
      title: '',
      message: ''
    },
    isSmallScreen: window.innerWidth <= 768,
    language: 'sv'
  }),
  computed: {
    items() {
      const t = this.$translate;
      this.$root.language; // Reference it
      return [
        { title: t('My addons'), icon: 'mdi-view-dashboard', link: '/list' },
        { title: t('Usage'), icon: 'mdi-chart-timeline-variant', link: '/stats' },
        { title: t('Sales'), icon: 'mdi-finance', link: '/sales' },
        { title: t('Settings'), icon: 'mdi-cog', link: '/settings' },
        { title: t('Review'), icon: 'mdi-eye', link: '/review', reviewer: true },
        { title: t('Branch packets'), icon: 'mdi-checkbox-blank-circle', link: '/branchpaket', id: 'branchpacket' }
      ]
    },
    visible_items() {
      return this.items.filter(i => {
        if(i.id == 'branchpacket' && !this.reviewer.admin) return false;
        if (this.reviewer && this.reviewer.restrictions.pages && this.reviewer.restrictions.pages.indexOf(i.link) == -1) return false;
        return (!i.reviewer || this.reviewer.list) && (!i.supplier || (this.supplier && this.developer && this.supplier.id == this.developer.id) );
      });
    }
  },
  created() {
    this.refetchDeveloper();
  },
  methods: {
    getDeveloper() {
      return this.developer_promise;
    },
    refetchDeveloper() {
      this.developer_promise = new Promise((resolve) => {
        fetch('/api/addon/supplier/data/get').then(response => {
          if (response.status == 200) {
            response.json().then(data => {
              this.reviewer = data.reviewer;
              this.developer = {id: data.developer_id, name: data.developer_name};
              this.suppliers = data.suppliers;
              this.supplier = _.find(this.suppliers, {id: this.getSettings().supplier});

              if (!this.supplier && this.suppliers.length >= 1) {
                this.supplier = this.suppliers[0];
              }

              if (this.$router.currentRoute.name == 'register') {
                this.$router.push({name: 'list'}).catch(() => {});
              } else {
                let pages = this.reviewer.restrictions && this.reviewer.restrictions.pages;
                if (pages && !pages.includes(this.$router.currentRoute.path)) {
                  this.$router.replace(pages[0]);
                }
              }

              if (!this.suppliers || !this.suppliers.length) {
                this.showNoDeveloperDialog = true;
              }

              if (data.developer_language == 'en') {
                fetchLanguageFile('en');
              }

              resolve();
            });
          } else {
            if (this.$router.currentRoute.name != 'register' && this.$router.currentRoute.name != 'setpassword') {
              this.$router.push({name: 'register'}).catch(() => {});
            }
          }
        });
      });
    },
    getSettings() {
      let settings = localStorage.getItem('addonportal');
      return settings ? JSON.parse(settings) : {};
    },
    setSupplier() {
      let settings = localStorage.getItem('addonportal');
      if (!settings) {
        settings = {};
      } else {
        settings = JSON.parse(settings);
      }
      settings.supplier = this.supplier.id;
      localStorage.setItem('addonportal', JSON.stringify(settings));
    },
    onLoggedOut() {
      this.developer = null;
      this.supplier = null;
      this.suppliers = [];
      this.reviewer = false;
      this.$router.push({name: 'register'});
    },
    logout() {
      fetch('/api/staff/logout', {method: 'POST'}).then(() => {
        this.onLoggedOut();
      });
    },
    showErrorDialog(title, message) {
      this.errorDialog.title = title;
      this.errorDialog.message = message;
      this.errorDialog.show = true;
    }
  },
  mounted() {
    this.$translations.setVueRoot(this);
    document.title = this.$translate(document.title);
  }
});

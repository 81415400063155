<template>
    <codemirror
        ref="editor"
        style="width: 100%"
        :value="value || ''"
        @input="$emit('input', $event)"
        :options="{...cmOptions, readOnly: readonly}"
        @focus="refreshEditor()"
    ></codemirror>
</template>

<script>
const options = {
    python: {
        tabSize: 4,
        indentUnit: 4,
        lineNumbers: true,
        matchBrackets: true,
        mode: 'python',
        readOnly: true,
        lineWrapping: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"]
    },
    js: {
        tabSize: 4,
        indentUnit: 4,
        lineNumbers: true,
        matchBrackets: true,
        mode: 'text/javascript',
        readOnly: true,
        lineWrapping: true,
        foldGutter: true,
        lint: {
            esversion: 11,
            asi: true
        },
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"]
    },
    json: {
        tabSize: 4,
        indentUnit: 4,
        lineNumbers: true,
        matchBrackets: true,
        mode: 'application/json',
        readOnly: true,
        lineWrapping: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"]
    },
    html: {
        tabSize: 4,
        indentUnit: 4,
        matchBrackets: true,
        mode: 'text/html',
        readOnly: false,
        lineNumbers: true,
        lineWrapping: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
    },
    css: {
        tabSize: 4,
        indentUnit: 4,
        matchBrackets: true,
        mode: 'text/css',
        readOnly: false,
        lineNumbers: true,
        lineWrapping: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
    }
};

export default {
    props: {
        value: {
        },
        type: {
           type: String 
        },
        readonly: {
            type: Boolean
        }
    },

    computed: {
        cmOptions() {
            let type = this.type;
            if (type.startsWith('python')) {
                type = 'python';
            } else if (type.startsWith('nodejs')) {
                type = 'js'
            }
            return options[type];
        }
    },

    methods: {
        refreshEditor() {
            this.$refs.editor.codemirror.refresh();
            setTimeout(() => {
                this.$refs.editor.codemirror.refresh();
            }, 500);
        }
    }
}
</script>
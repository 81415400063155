<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Bekräfta</span>
      </v-card-title>

      <v-card-text v-text="text" />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="_close">Avbryt</v-btn>
        <v-btn color="error" text @click="_confirmed">Ta bort</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'confirm-remove',
  data: () => ({
    showDialog: false,
    text: '',
    resolve: null,
    reject: null
  }),
  methods: {
    show(text) {
      this.showDialog = true
      this.text = text;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      }).catch(() => {});
    },
    _confirmed() {
      this.showDialog = false;
      this.resolve();
    },
    _close() {
      this.showDialog = false;
    }
  }
}
</script>

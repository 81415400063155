<template>
<div>
  <div class="title">Beskriv för kunderna hur tillvalet fungerar</div>

  <v-alert
    border="left"
    colored-border
    type="info"
    elevation="2"
  >
    Detta kommer visas för alla kunder i deras system när de vill läsa om tillvalet, och även på vår hemsida. Beskriv kortfattat vad tillvalet är till för och hur det fungerar för personal och medlemmar.
  </v-alert>

  <v-card max-width="900px">
    <div class="close-btn">
      <v-icon>mdi-close</v-icon>
    </div>
    <v-card-title>
      <v-row>
        <v-col cols=12 md=4>
          <span class="headline">{{item.name}}</span>
        </v-col>
        <v-col cols=12 md=4 style="text-align: center; font-size: 0.7em; font-weight: normal;">
          {{price}}
        </v-col>
        <v-col cols=12 md=4>
          <v-btn color="success float-md-right">Lägg till sida {{item.name}}</v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <input ref="uploader" class="d-none" type="file" accept="image/*" @change="uploadImage($event.target.files[0])" />

    <v-card-text
        v-for="(page, index) in pages" :key="index"
        v-show="page == active">
      <v-row>
        <v-col v-for="(part, index) in [page.left, page.right]" :key="index" cols=12 md=6>
          <v-row>
            <v-col cols=12 md=12 style="position: relative;">

              <div class="add-image">
                <v-icon @click="addImage(part)" large>mdi-plus-circle</v-icon>
              </div>

              <v-carousel
                height="200" width="400"
                :hide-delimiters="item.screenshots.length == 0"
                show-arrows-on-hover hide-delimiter-background
                v-model="part.img"
                @change="onChangeImage()">
                  <v-carousel-item
                    v-for="screenshot in item.screenshots"
                    :key="screenshot"
                    >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-img :src="screenshot" max-height="200px" max-width="400px" contain  />
                    </v-row>
                  </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>

          <v-row style="position: relative;">
            <div class="change-type">
              <v-icon @click="changeType(part, 0)">mdi-text</v-icon>
              <v-icon @click="changeType(part, 1)">mdi-format-list-bulleted</v-icon>
            </div>

            <v-col v-if="part.type == 'list'" cols=12 md=12>
              <h1 class="v-heading text-h5 mb-2 editable" v-editor="part.header" @update="part.header = $event.value; update();"></h1>
              <ul class="green-list">
                <li class="editable"
                  v-for="(line, index) in part.list" :key="index"
                  @focus="listFocus(index, part)" @blur="listBlur(index, part)"
                  v-editor="line" @update="$set(part.list, index, $event.value); listInput(index, part); update();"></li>
              </ul>
            </v-col>

            <v-col v-else cols=12 md=12>
              <h1 class="v-heading text-h5 mb-2 editable" v-editor="part.header" @update="part.header = $event.value; update();"></h1>
              <p class="editable" style="padding: 1px" v-editor="part.content" @update="part.content = $event.value; update();"></p>
            </v-col>
          </v-row>

        </v-col>

      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="prevPage()" text :disabled="firstPage()">Tillbaka</v-btn>

      <div style="margin-right: 1em; padding-top: 8px;">
        <div
          v-for="(page, index) in pages" :key="index"
          v-bind:class="{ 'pagination-inactive': page != active }"
          @click="selectPage(page)"
          class="pagination"></div>
      </div>

      <v-btn color="success" @click="nextPage()" :disabled="lastPage()">Nästa</v-btn>

      <v-spacer></v-spacer>
      <v-btn color="default" @click="addPage()">Lägg till sida</v-btn>
      <v-btn color="error" @click="deletePage()" :disabled="pages.length <= 1">Ta bort sida</v-btn>
    </v-card-actions>
  </v-card>
</div>
</template>

<script>
export default {
  name: 'v-preview',
  props: ['item'],
  data: () => ({active: null, pages: [], activePart: null}),
  mounted() {
    let p = this.item.preview || {};
    if(!p.pages || !p.pages.length) {
      this.pages = p.pages = [];
      this.addPage();
      this.addPage();
      this.addPage();
      this.active = this.pages[0];
      this.pages[0].left.header = `Möjligheter med ${this.item.name}`;
      this.pages[0].right.header = '';
      this.pages[1].left.header = `Så fungerar det för kunderna`;
      this.pages[1].right.header = '';
      this.pages[2].left.header = `Så fungerar det för personalen`;
      this.pages[2].right.header = '';
      this.update();
    } else {
      this.pages = p.pages;
      this.active = this.pages[0];
    }
  },
  methods: {
    update() {
      const clean = p => {
        let r = {
          img: p.img,
          link: p.link,
          header: p.header,
          type: p.type
        };
        if(r.type == 'list') r.list = p.list.filter(s => s);
        else r.content = p.content;
        return r;
      }
      let r = {
        pages: this.pages.map(p => {
          return {
            left: clean(p.left),
            right: clean(p.right)
          };
        })
      };
      this.$emit('update', r);
    },
    firstPage() {
      return this.pages[0] == this.active;
    },
    lastPage() {
      return this.pages[this.pages.length - 1] == this.active;
    },
    prevPage() {
      let i = this.pages.indexOf(this.active);
      if(i > 0) this.active = this.pages[i-1];
    },
    nextPage() {
      let i = this.pages.indexOf(this.active);
      if(i >= 0) this.active = this.pages[i+1];
    },
    deletePage() {
      if(this.pages.length <= 1) return;
      let i = this.pages.indexOf(this.active);
      if(i < 0) return;
      this.pages.splice(i, 1);
      this.active = this.pages[i] || this.pages[0];
      this.update();
    },
    addPage() {
      this.active = {
        left: {
          type: null,
          img: null,
          header: 'Rubrik',
          content: 'Information'
        },
        right: {
          img: null,
          type: 'list',
          header: 'Funktionalitet',
          list: ['Punkt']
        }
      };
      this.pages.push(this.active);
      this.update();
    },
    selectPage(page) {
      this.active = page;
    },
    listFocus(i, part) {
      if(i != part.list.length - 1) return;
      if(part.list[part.list.length - 1]) part.list.push('');
    },
    listBlur(i, part) {
      if(!i || i != part.list.length - 1) return;
      if(!part.list[part.list.length - 1]) part.list.pop();
    },
    listInput(i, part) {
      let list = part.list;
      if(i == list.length - 1) {
        if(list[list.length - 1]) list.push('');
      } else if(i == list[list.length - 2]) {
        if(!list[list.length - 1]) list.pop();
      }
      this.update();
    },
    onChangeImage() {
      this.active.left.link = this.item.screenshots[this.active.left.img];
      this.active.right.link = this.item.screenshots[this.active.right.img];
      this.update();
    },
    changeType(part, list) {
      if(list) {
        if(part.type != 'list') {
          part.type = 'list';
          part.list = (part.content || '').split('\n').filter(s => s);
        }
      } else {
        if(part.type == 'list' && part.list) {
          part.content = part.list.join('\n');
        }
        if(!part.content) part.content = 'Information';
        part.type = null;
      }
      this.update();
    },
    addImage(part) {
      this.$refs.uploader.click();
      this.activePart = part;
    },
    uploadImage(file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        let data = {image: fr.result};
        fetch('/api/addon/supplier/image/upload', {method: 'POST', body: JSON.stringify(data) }).then(response => {
          response.json().then(image => {
            this.item.screenshots.push(image.url);
            this.$refs.uploader.value = null;
            this.activePart.img = this.item.screenshots.length - 1;
            this.activePart.link = image.url;
            this.update();
          })
        });
      })
    }
  },

  computed: {
    price() {
      if(this.item.price_per_month) return `Pris: ${this.item.price_per_month} kr/mån`;
      if(this.item.start_fee) return `Pris: ${this.item.start_fee} kr`;
      return '';
    }
  },

  directives: {
    editor: {
      bind(el, binding) {
        el.setAttribute('contenteditable', true);
        el.innerText = binding.value;
        const fn = () => {
          let e = new CustomEvent('update');
          e.value = el.innerText;
          el.dispatchEvent(e);
        };

        el.addEventListener('input', fn);
        el._destroy = () => {
          el.removeEventListener('input', fn);
        }
        el._update = (value) => {
          if(el.innerText != value) el.innerText = value;
        }
      },
      update(el, binding) {
        el._update(binding.value);
      },
      unbind(el) {
        el._destroy();
      }
    }
  }
}
</script>

<template>
  <div>
    <h1>Användning</h1>

      <v-row>
        <v-col cols=4>
          <v-autocomplete
                  :items="addons_for_supplier"
                  label="Välj tillval"
                  solo
                  v-model="addon"
                  item-text="name"
                  return-object
                  @change="loadStats"
          ></v-autocomplete>
        </v-col>

        <v-col cols=4>
          <v-menu
            v-model="showDateMenu.fromDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fromDate"
                label="Visa användare från datum"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" @input="showDateMenu.fromDate = false" @change="loadStats" first-day-of-week="1"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols=4>
          <v-menu
            v-model="showDateMenu.toDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="toDate"
                label="Visa användare till datum"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="toDate" @input="showDateMenu.toDate = false" @change="loadStats" first-day-of-week="1"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

    <v-row v-if="addon">
      <v-chart :options="eusage" style="width: 100%" />
    </v-row>

    <div v-if="$root.reviewer.list">
      <h2>System med visst tillval</h2>
      <v-row>
          <v-col cols=4>
            <v-autocomplete
                    :items="allAddons"
                    label="Välj tillval"
                    solo
                    v-model="addonSystems"
                    item-text="name"
                    return-object
                    @change="loadSystemStats"
            ></v-autocomplete>
          </v-col>
      </v-row>

      <v-progress-circular v-if="fetchingSystemStats" indeterminate color="primary" />
      <div v-else>
        Antal system: {{systemsUsingAddon.length}}

        <div v-for="system in systemsUsingAddon" :key="system">
          {{system}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {getAddons} from '@/utils.js';

export default {
  name: 'statsComponent',

  data: () => ({
    addons: [],
    addon: null,
    addonSystems: null,
    fetchingSystemStats: false,
    systemStats: null,
    domains: [],
    allAddons: [],
    fromDate: new Date().addDays(-60).yyyymmdd(),
    toDate: new Date().yyyymmdd(),
    showDateMenu: {fromDate: false, toDate: false},
    usage: [],
    eusage: {
      title: {
        text: 'Antal som har tillvalet igång'
      },
      xAxis: {
          type: 'category',
          data: []
      },
      yAxis: {
          type: 'value'
      },
      series: [{
          data: [],
          type: 'bar',
          itemStyle: {
              color: '#349df1'
          },
          areaStyle: {
              color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                      offset: 0, color: '#349df1' // color at 0% position
                  }, {
                      offset: 1, color: 'white' // color at 100% position
                  }],
                  global: false // false by default
              }
          },
          smooth: true
      }],
      tooltip: {},
      dataZoom: [{
        type: 'inside'
      },{
        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '80%',
        handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
        }
      }]
    }
  }),
  methods: {
    loadStats() {
      if (!this.addon) {
        return;
      }
      fetch('/api/addon/supplier/stats/users/get?addon_id=' + this.addon.id + '&fromDate=' + this.fromDate + '&toDate=' + this.toDate).then(response => {
        response.json().then(data => {
          this.usage = data.map(v => v[1])

          this.eusage.xAxis.data = data.map(v => v[0]);
          this.eusage.series[0].data = data.map(v => v[1]);
        })
    });
    },
    loadSystemStats() {
      if (this.fetchingSystemStats) {
        return;
      }
      this.fetchingSystemStats = true;
      let addon_id = this.addonSystems.id;
      fetch('/api/addon/stats/get?addon_id=' + addon_id).then(response => {
        response.json().then(data => {
            this.fetchingSystemStats = false;
            if (addon_id == this.addonSystems.id) {
              this.systemStats = data;
            } else {
              this.loadSystemStats();
            }
        })
      })
    },
    getDomainName(domain) {
      return domain.name + ' (' + domain.hostname + ')';
    }
  },
  computed: {
    addons_for_supplier() {
      return this.addons.filter(x => this.$root.supplier && x.supplier_id == this.$root.supplier.id).sort( (a, b) => a.name.localeCompare(b.name));
    },
    systemsUsingAddon() {
      if (!this.systemStats || !this.addonSystems) {
        return [];
      }

      return this.systemStats.filter(x => x.addon_id == this.addonSystems.id).map(x => {
        let domain = this.domains.find(d => d.id == x.domain_id)
        domain = domain ? this.getDomainName(domain) : String(x.domain_id);
        return domain;
      } ).sort((a,b) => a.localeCompare(b))
    }
  },
  mounted() {
    getAddons().then(data => {
      this.addons = [];
      data.forEach(x => {
        let hasLive = false;
        x.versions.forEach(v => {
          if (v.state == 'live') {
            hasLive = true;
          }
        });

        if (hasLive) {
          this.addons.push(x);
        }
      });

      this.loadStats();
    });

    this.$root.getDeveloper().then(() => {
      if (this.$root.reviewer && this.$root.reviewer.list) {
        fetch('/api/addon/supplier/data/get').then(response => response.json()).then(data => {
          this.domains = data.domains.sort((a, b) => this.getDomainName(a).localeCompare(this.getDomainName(b)));
        })

        fetch('/api/addon/review/get').then(response => response.json() ).then(data => {
          this.allAddons = data.sort((a,b) => a.name.localeCompare(b.name));
        });
      }
    })
  }
}
</script>

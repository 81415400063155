<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{getHeadline()}}</span>
      </v-card-title>

      <v-card-text>
        {{getText()}}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="_close">Avbryt</v-btn>
        <v-btn color="primary" text @click="_confirmed">{{getButton()}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'confirm-dialog',
  props: ['headline', 'text', 'button'],
  data: () => ({
    showDialog: false,
    resolve: null,
    reject: null
  }),
  methods: {
    show() {
      this.showDialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      }).catch(() => {});
    },
    getHeadline() {
      return this.headline || 'Bekräfta';
    },
    getText() {
      return this.text || 'Är du säker?';
    },
    getButton() {
      return this.button || 'Fortsätt';
    },
    _confirmed() {
      this.showDialog = false;
      this.resolve();
    },
    _close() {
      this.showDialog = false;
    }
  }
};
</script>

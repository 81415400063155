<template>
  <div>
    <v-row>
      <v-col cols=4>
        <v-select
          :items="statusTypes"
          label="Välj status"
          v-model="selectedStatus"
          item-text="name"
          item-value="id"
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols=4>
        <v-text-field
          v-model="addon_search"
          append-icon="mdi-magnify"
          label="Sök"
          single-line
          hide-details
          @keydown="searchKeyDown"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <v-data-table
          ref="reviewaddontable"
          :headers="headers"
          :items="filtered_addons"
          class="elevation-1"
          :hide-default-header="$root.isSmallScreen"
          :hide-default-footer="true"
          must-sort
          :items-per-page="1000"
          :search="addon_search"
          @click:row="rowClick"
        >
          <template v-slot:item.image="{ item }">
            <img v-if="item.version.image" :src="item.version.image" width="50" height="50" />
          </template>
          <template v-slot:item.state="{ item }">
            {{getState(item.version.state)}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'reviewAddonsComponent',
  data: () => ({
    selectedStatus: 'review',
    addon_search: '',
    statusTypes: [{id: 'test', name: 'Under utveckling'}, {id: 'live', name: 'Publicerad'}, {id: 'review', name: 'Granskas'}],
    headers: [{ text: '', value: 'image', sortable: false},{ text: 'Namn', value: 'name'}, {text: 'ID', value: 'id'}, {text: 'Utvecklare', value: 'version.supplier_name'},  {text: 'Version', value: 'version.version_number'}, {text: 'Status', value: 'state'}, {text: 'Senast ändrad', value: 'version.last_modified'}],
    addons: []
  }),
  mounted() {
    fetch('/api/addon/review/get').then(response => response.json() ).then(data => {
      data.forEach( (addon) => {
        addon.version = _.find(addon.versions, {state: 'review'}) || _.find(addon.versions, {state: 'test'}) || _.find(addon.versions, {state: 'live'});
      });
      this.addons = data;
    });
  },
  computed: {
    filtered_addons() {
      return this.addons.filter(a => a.version &&  (!this.selectedStatus || a.version.state == this.selectedStatus));
    }
  },
  methods: {
    rowClick(addon) {
      this.$router.push({name: 'show', params: {addon_id: addon.id}, props: {addon: _.cloneDeep(addon)}});
    },
    getState(state) {
      switch (state) {
        case 'test': return 'Under utveckling';
        case 'review': return 'Granskas';
        case 'live': return 'Publicerad';
      }
    },
    searchKeyDown(e) {
      if (e.keyCode == 13 && this.$refs.reviewaddontable.selectableItems.length == 1) {
        this.rowClick(this.$refs.reviewaddontable.selectableItems[0]);
      }
    },
  }
}
</script>

<template>
    <div>
        <template v-if="canAddDeveloper">
            <v-btn color="primary" dark class="mb-2" @click="newDeveloper()">{{$translate('Add developer')}}</v-btn>
            <data-dialog ref="account_dialog" :title="$translate('Add developer')" :fields="developer_fields" />
            <v-data-table
                ref="accounttable"
                :headers="headers"
                :items="$root.supplier ? $root.supplier.developers : []"
                class="elevation-1"
                :hide-default-header="false"
                :hide-default-footer="true"
                :items-per-page="1000"
            >
                <template v-slot:item.name="{ item }">
                    {{item.firstname}} {{item.lastname}}
                </template>
                <template v-slot:item.action="{ item }">
                        <v-icon v-if="item.id != $root.supplier.id"
                            small
                            @click="removeDeveloper(item)"
                        >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </template>

        <v-row class="mt-12">
            <v-col md=3>
                <v-select  :items="[{text: 'Svenska', value: 'sv'},{text: 'English', value: 'en'}]" :label="$translate('Language')" v-model="$root.language">
                    <template v-slot:selection="{ item }">
                        <img :src="`/assets/img/flags/${item.value}.png`" class="mr-2" />{{ item.text }}
                    </template>
                    <template v-slot:item="{ item }">
                        <img :src="`/assets/img/flags/${item.value}.png`" class="mr-2" />{{ item.text }}
                    </template>
                </v-select>
            </v-col>
        </v-row>

        <v-row class="mt-12" v-if="git && gitStatus != null">
            <v-col md=12 v-if="gitStatus.started">
                <h2>{{$translate('Git is enabled')}}</h2>
                <h3>{{$translate('SSH keys')}}</h3>

                <div v-if="keys.length == 0" style="opacity: 0.5">
                    {{$translate('No SSH keys found')}}
                </div>
                <template v-else>
                    <div v-for="key in keys" :key="key.id">
                        <span v-text="key.key" />
                        <v-btn class="ml-4" color="error" small @click="removeKey(key)" :loading="removingKey">{{$translate('Remove')}}</v-btn>
                    </div>
                </template>

                <div class="mt-4">
                    <v-textarea v-model="newKey" :label="$translate('SSH public key')" v-if="addKeyMode" />
                    <div class="d-flex">
                        <v-btn color="primary" @click="addKey" :loading="addingKey">{{$translate('Add SSH key')}}</v-btn>
                        <v-btn class="ml-4" v-if="addKeyMode" color="secondary" @click="addKeyMode = false">{{$translate('Cancel')}}</v-btn>
                    </div>
                </div>
            </v-col>
            <v-col md=12 v-else>
                <v-btn color="primary" @click="enableGit" :loading="enablingGit">{{$translate('Enable Git')}}</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'accountsComponent',
    computed: {
        headers() {
            const t = this.$translate;
            this.$root.language; // Reference it
            return [{ text: t('Name'), value: 'name'}, { text: t('E-mail'), value: 'mail'}, {text: '', value: 'action', sortable: false}];
        },
        developer_fields() {
            const t = this.$translate;
            this.$root.language; // Reference it
            return [
                {id: 'firstname', label: t('First name'), type: 'text', required: true, value: ''},
                {id: 'lastname', label: t('Last name'), type: 'text', required: true, value: ''},
                {id: 'mail', label: t('E-mail'), type: 'text', required: true, value: ''}
            ]
        },
        canAddDeveloper() {
            return this.$root.supplier && this.$root.developer && this.$root.supplier.id == this.$root.developer.id;
        },
        git() {
            return window.localStorage.getItem('git');
        }
    },
    data: () => ({
        keys: [],
        newKey: '',
        addKeyMode: false,
        addingKey: false,
        removingKey: false,
        gitStatus: null,
        enablingGit: false
    }),
    methods: {
        newDeveloper() {
            let self = this;
            this.$refs.account_dialog.show().then(function(developer) {
                self.saveNewDeveloper(developer);
            });
        },
        saveNewDeveloper(developer) {
            let data = {firstname: developer.firstname, lastname: developer.lastname, mail: developer.mail};
            fetch('/api/addon/supplier/developer/add', {method: 'POST', body: JSON.stringify(data)}).then(() => {
                this.$root.refetchDeveloper();
            });
        },
        removeDeveloper(developer) {
            let data = {id: developer.id};
            fetch('/api/addon/supplier/developer/remove', {method: 'POST', body: JSON.stringify(data)}).then(() => {
                this.$root.refetchDeveloper();
            });
        },
        getGitStatus() {
            fetch('/api/addon/supplier/developer/git/status').then(x => x.json()).then(x => {
                this.gitStatus = x;
            });
        },
        getKeys() {
            fetch('/api/addon/supplier/developer/keys/get').then(x => x.json()).then(x => {
                this.keys = x;
            });
        },
        removeKey(key) {
            this.removingKey = true;
            fetch(`/api/addon/supplier/developer/keys/remove?id=${key.id}`, {method: 'POST'}).then((response) => {
                this.removingKey = false;
                if (response.status != 200) {
                    this.$root.showErrorDialog('Error', 'Could not remove SSH key');
                } else {
                    this.getKeys();
                }
            });
        },
        addKey() {
            if (!this.addKeyMode)  {
                this.addKeyMode = true;
                return;
            }
            this.addingKey = true;
            fetch('/api/addon/supplier/developer/keys/add', {method: 'POST', body: JSON.stringify({key: this.newKey})}).then((response) => {
                this.addingKey = false;
                if (response.status != 200) {
                    this.$root.showErrorDialog('Error', 'Could not add SSH key. Make sure the key length is enough. For RSA, minimum length is 3071 chars.');
                } else {
                    this.newKey = '';
                    this.getKeys();
                    this.addKeyMode = false;
                }
            });
        },
        enableGit() {
            this.enablingGit = true;
            fetch('/api/addon/supplier/developer/git/enable', {method: 'POST'}).then((response) => {
                this.enablingGit = false;
                if (response.status != 200) {
                    this.$root.showErrorDialog('Error', 'Could not enable Git');
                } else {
                    this.getGitStatus();
                }
            });
        }
    },
    mounted() {
        this.getGitStatus();
        this.getKeys();
    }
}
</script>

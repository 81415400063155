<template>
    <div class="mb-10">
        <v-tabs v-model="tab">
            <v-tab v-for="tab in tabs" :key="tab.title">{{tab.title}}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item class="pt-4" v-for="tab in tabs" :key="tab.title">
                <v-row v-for="greeting in getGreetings(tab)" :key="greeting.id">
                    <v-col cols=12 md=6>
                        <packagegreetingeditcard :item="greeting" @update="updateGreeting" @remove="removeGreeting" />
                    </v-col>
                </v-row>
                <v-btn color="primary" @click="addGreeting(tab)">+ Lägg till ny hälsning</v-btn>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { getRandomId } from '@/utils.js';

export default {
    name: 'packagegreetinglist',
    props: ['package'],
    data: () => ({
        tab: null,
    }),
    computed: {
        tabs() {
            return [
                { title: 'I testsystem', live: false },
                { title: 'I aktivt system', live: true }
            ];
        }
    },
    methods: {
        getGreetings(tab) {
            return this.package.greetings.filter(g => g.live == tab.live);
        },
        addGreeting(tab) {
            this.package.greetings.push({ id: `new_${getRandomId()}`, package_id: this.package.id, live: tab.live });
        },
        updateGreeting(greeting) {
            let index = _.findIndex(this.package.greetings, { id: greeting.id });
            if (index !== -1) {
                this.package.greetings[index] = greeting;
            }
        },
        removeGreeting(id) {
            let index = _.findIndex(this.package.greetings, { id });
            if (index !== -1) {
                this.package.greetings.splice(index, 1);
            }
        }
    }
};

</script>
